import React from "react";
import {
  TextField,
  ITextFieldStyleProps,
  ITextFieldStyles,
  ILabelStyleProps,
  ILabelStyles,
  ITextFieldProps,
  IChoiceGroupProps,
  Dropdown,
  IDropdownProps,
  IDropdownOption,
  FontWeights
} from "@fluentui/react";
import { useFormState } from "@d4b/fluent-ui/utils";


export const isValidEmail = (val: string) => {
  // eslint-disable-next-line no-useless-escape
  let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEmail.test(val);
};
function isObject(val: any) {
  return val != null && typeof val === "object" && Array.isArray(val) === false;
}

function cleanObject(obj: any, valueToClean: any = [null]): object {
  if (!isObject(obj)) {
    return obj;
  }

  const cleanObj: object = {};
  let filter = valueToClean;

  for (let key in obj) {
    const objValue = obj[key];

    if (Array.isArray(valueToClean)) {
      filter = (val: any) => valueToClean.includes(val);
    } else if (typeof valueToClean !== "function") {
      filter = (val: any) => val === valueToClean;
    }

    if (isObject(objValue)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (cleanObj as any)[key] = cleanObject(objValue, filter);
    } else if (!filter(objValue)) {
      (cleanObj as any)[key] = objValue;
    }
  }
  return cleanObj;
}

export type FormFieldTextProps<State> = ITextFieldProps & {
  formState?: useFormState<State>;
  visible?: boolean
  // onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
};

export const FormTextFieldRounded = (props: FormFieldTextProps<any>) => {
  const { formState, name, readOnly, onKeyDown, style } = props;
  let propsOverride = props;

  if (formState && name) {
    const { errors, addField } = formState;
    addField(name);
    propsOverride = {
      style: readOnly
        ? { backgroundColor: "#f9f9f9", borderRadius: 120, ...style }
        : { ...style },
      ...props,
      onChange: formState.onChange,
      onKeyDown,
      errorMessage: errors[name],
      value: formState.values[name],
    };
  }
  return <TextField {...propsOverride} borderless styles={getStyles} />;
};

export const FormTextField = (props: FormFieldTextProps<any>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { formState, name, onKeyDown, visible = true } = props;
  let propsOverride = props;

  if (formState && name) {
    const { errors, addField } = formState;
    addField(name);
    propsOverride = {
      // style: readOnly
      //   ? { backgroundColor: "#f9f9f9", borderRadius: 120, ...style }
      //   : { ...style },
      ...props,
      onChange: formState.onChange,
      onKeyDown,
      errorMessage: errors[name],
      value: formState.values[name],
    };
  }
  if(!visible) return null;
  return (
    <TextField
      {...propsOverride}
    />
  );
};

export const descriptionStyles = {
  root: {
    // color: theme.palette.green,
    fontWeight: FontWeights.bold,
  },
};



export type FormDropdownProps<State> = IDropdownProps & {
  name: string;
  formState?: useFormState<State>;
  visible?: boolean;
};
export const FormDropdown = (props: FormDropdownProps<any>) => {
  const [selectedKeys, setSelectedKeys] = React.useState<any[]>(props?.selectedKeys || []);
  let propsOverride = props;
  const onSelectChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): string|undefined => {
    if (item) {
      const keys = item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter(key => key !== item.key);
      setSelectedKeys(keys);
      return (keys.join(";"));
    }
  };

  const { formState, name, onKeyDown, onChange, visible = true , required = false} = props;
  if (formState && name) {
    const { addField, errors, values, setErrors, setValues } = formState;
    addField(name);
    propsOverride = {
      ...props,
      onChange: (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption) => {
         
        var newValues={};
        if(!props.multiSelect)  
          newValues = { ...values, [name]: option?.key || "" };
        else
          newValues = { ...values, [name]: onSelectChange(event,option) || "" };
        
        // Clear errors
        if(errors[name] && setErrors) setErrors({ ...errors, [name]: undefined }) ;
        newValues && setValues(newValues);
        onChange && onChange(event, option);
      },
      onKeyDown,
      errorMessage: errors[name],
      // value: formState.values[name],
    };
    if(props.multiSelect)
      propsOverride.selectedKeys = selectedKeys;
  }
  return visible ? <Dropdown required={required} {...propsOverride} /> : null;

};

export type FormChoiceGroupProps<State> = IChoiceGroupProps & {
  formState?: useFormState<State>;
};


/**export const FormChoiceGroup = (props: FormChoiceGroupProps<any>) => {
  const { formState, name, readOnly, onKeyDown } = props;
  let propsOverride = props;

  if (formState && name) {
    const { addField } = formState;
    addField(name);
    const { values, setValues } = formState;
    propsOverride = {
      ...props,
      onChange: (
        _ev: React.SyntheticEvent<HTMLElement>,
        option: IChoiceGroupOption
      ) => {

        const newValues = { ...values, [name]: option.key || "" };
          setValues(newValues);
      },
      onKeyDown,
      value: formState.values[name],
    };
  }

  return <ChoiceGroup {...propsOverride} />;
};*/

export function getStyles(
  props: ITextFieldStyleProps
): Partial<ITextFieldStyles> {
  const { required, disabled } = props;

  return {
    root: { width: "100%", paddingTop: 24, borderRadius: 120 },
    wrapper: {
      borderRadius: 120,
      backgroundColor: disabled ? "green" : undefined,
    },
    fieldGroup: [
      {
        width: "100%",
        border: "1px solid rgba(0,0,0,0.2);",
        borderRadius: 120,
      },
      required &&
        {
          // borderTopColor: props.theme.semanticColors.errorText,
        },
    ],
    subComponentStyles: {
      label: getLabelStyles,
    },
  };
}

function getLabelStyles(props: ILabelStyleProps): ILabelStyles {
  const { required } = props;
  return {
    root: required && {
      color: props.theme.palette.themePrimary,
    },
  };
}
