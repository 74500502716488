import { ActionButton, Icon, IDetailsColumnProps } from "@fluentui/react";
import { theme } from "App";
import { UseUrlParams } from "@d4b/fluent-ui";

type TableColumnSortBtnProps = {
  item?: IDetailsColumnProps;
  filterParams: UseUrlParams;
  customSort?: any[];
};

export const TableColumnSortBtn = ({
  item,
  filterParams,
  customSort,
}: TableColumnSortBtnProps) => {
  const defaultColIndex = customSort?.findIndex(
    (a) => a.field === item?.column.fieldName
  );
  const i = defaultColIndex && defaultColIndex !== -1 ? defaultColIndex : 0;
  const activeColumn: any = filterParams.localStorageSortObj[i];
      
  return (
    <ActionButton
      onClick={() => {
        const direction = activeColumn?.field === item?.column.fieldName
          ? activeColumn?.direction === "asc"
            ? "desc"
            : "asc"
          : "asc";
        customSort?.map((c) => (c.direction = direction));
        
        filterParams.setLocalStorageSort(
          JSON.stringify(
            customSort || [
              {
                field: item?.column.fieldName,
                direction: direction,
              },
            ]
          )
        );
      }}
    >
      {activeColumn?.field === item?.column.fieldName && (
        <Icon
          style={{
            marginTop: 0,
            marginLeft: -5,
            marginRight: 5,
            fontSize: 18,
            height: 12,
            color: theme.semanticColors.actionLink,
          }}
          iconName={
            activeColumn?.field === item?.column.fieldName
              ? activeColumn?.direction === "desc"
                ? "Descending"
                : "Ascending"
              : ""
          }
        />
      )}
      {item?.column.name}
    </ActionButton>
  );
};
