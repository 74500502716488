import {
  Callout,
  DefaultButton,
  DefaultEffects,
  FontWeights,
  IButtonStyles,
  IconButton,
  IStackStyles,
  IStyle,
  mergeStyles,
  mergeStyleSets,
  Stack,
  Text,
} from "@fluentui/react";
import pkg from "../../../package.json";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { themePalette } from "App";
import React, { ReactElement } from "react";
import { useAppContext, AppMenuPanel, UserPreferencePanel } from "lib";

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 320,
    maxWidth: "90%",
    padding: "12px 24px",
  },
  title: {
    marginTop: 12,
    fontWeight: FontWeights.bold,
  },
});
type VersionTableProps = {
  children?: React.ReactNode;
};
const VersionTable = ({ children }: VersionTableProps) => (
  <table>
    <tbody>{children}</tbody>
  </table>
);

type VersionLineProps = {
  text: string;
  version?: string;
};
export const VersionLine = ({
  text,
  version = "undefined",
}: VersionLineProps) => (
  <tr style={{ marginBottom: 5 }}>
    <td style={{ marginRight: 5 }}>
      <Text variant="medium">
        <span className={styles.title}>{text}</span>
      </Text>
    </td>
    <td>
      <Text>{version}</Text>
    </td>
  </tr>
);

type HeaderBarProps = {
  title?: string;
  children?: React.ReactNode;
};

export const LinkMyDataLogo = mergeStyles({
  width: "120px",
  display: "block",
  color: "white",
});

export const AppHeader = ({
  title = "",
  children,
}: HeaderBarProps): ReactElement => {
  // let auth = useAuth();
  // const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
  //   useBoolean(false);

  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");

  const [
    isOpenRight,
    { setTrue: openPanelRight, setFalse: dismissPanelRight },
  ] = useBoolean(false);

  const { ws } = useAppContext();

  const stylesRoot: IStackStyles = {
    root: {
      margin: "0 auto",
      width: "100%",
      height: 48,
      lineHeight: 48,
      boxShadow: DefaultEffects.elevation8,
      backgroundColor: "#484644",
      color: themePalette.themeLighterAlt,
    },
  };
  const RightTopIconStyle: IButtonStyles = {
    root: {
      color: "inherit",
      backgroundColor: "inherit",
      width: 48,
      height: 48,
    },
    rootHovered: {
      backgroundColor: themePalette.neutralDark,
      color: themePalette.white,
    },
  };

  const TopLeftGroupeStack: IStackStyles = {
    root: { display: "flex", alignItems: "center", flexFlow: "inherit" },
  };
  const MiddleStack: IStackStyles = {
    root: { width: "100%", flexFlow: "inherit" },
  };

  const badgeTextStyle: IStyle = {
    height: 25,
    backgroundColor: "rgb(116, 124, 129)",
    color: "#FFF",
    lineHeight: "18px",
    padding: "2px 10px",
    borderRadius: 4,
    marginRight: 5,
    fontWeight: 400,
    whiteSpace: "nowrap",
  };

  return (
    <>
      {/* <SettingsPanel isOpen={isOpen} dismissPanel={dismissPanel} /> */}
      <AppMenuPanel isOpen={isOpenRight} dismissPanel={dismissPanelRight} />
      <Stack horizontal styles={stylesRoot}>
        <Stack horizontalAlign="center" horizontal>
          <IconButton
            styles={RightTopIconStyle}
            iconProps={{ iconName: "WaffleOffice365" }}
            onClick={openPanelRight}
          />
          <Text
            variant="mediumPlus"
            styles={{
              root: {
                fontWeight: 600,
                color: "#FFF",
                marginLeft: "5px !important",
                width: 145,
              },
            }}
          >
            {ws?.name}
          </Text>
        </Stack>
        <Stack styles={MiddleStack}></Stack>
        <Stack styles={TopLeftGroupeStack} verticalAlign="center">
          <DefaultButton
            id={buttonId}
            onClick={toggleIsCalloutVisible}
            text={ws?.RS_APP_LABEL}
            styles={{ root: badgeTextStyle, label: { fontWeight: 400 } }}
          />
          {isCalloutVisible && (
            <Callout
              className={styles.callout}
              gapSpace={0}
              target={`#${buttonId}`}
              onDismiss={toggleIsCalloutVisible}
              setInitialFocus
            >
              <VersionTable>
                <VersionLine text="Frontend" version={`v${pkg.version}`} />
                <VersionLine text="Backend" version={`v${ws?.version}`} />
                <VersionLine text="Runtime" version={ws?.runtime_version} />
                <VersionLine
                  text="Migrated"
                  version={new Date(ws?.migrated_at + "Z").toLocaleString()}
                />
              </VersionTable>
            </Callout>
          )}
          <UserPreferencePanel />
        </Stack>
      </Stack>
    </>
  );
};

export default AppHeader;
