import { useState } from "react";
import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
  DefaultEffects,
} from "@fluentui/react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "@d4b/fluent-ui";

export const LoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { signin, signout, isAuthenticated, isLoading, error } = useAuth();

  let { from }: any = location.state || { from: { pathname: "/" } };

  const [username, setUserName] = useState<string>();
  const [password, setPassword] = useState<string>();

  //! TODO: Allows for a common alert flash: Info, Warn, Error

  let login = () => {
    signin(username!, password!, () => {
      history.replace(from);
      return;
    });
  };

  if (isAuthenticated)
    return (
      <Stack>
        <Text>You are authenticated</Text>
        <PrimaryButton
          text="Logout"
          onClick={() => signout((_: any) => history.replace("/login"))}
        />
      </Stack>
    );

  return (
    <Stack
      // horizontal
      // grow
      verticalAlign="center"
      horizontalAlign="center"
      style={{ height: "100vh", backgroundColor: "#FAFAFA" }}
    >
      <Stack
        style={{
          boxShadow: DefaultEffects.elevation8,
          paddingBlock: 20,
          paddingInline: 20,
          width: 320,
        }}
      >
        <Text
          style={{ display: "block", textAlign: "center", marginBottom: 5 }}
          variant="xLarge"
        >
          Sign in
        </Text>

        {error && (
          <MessageBar
            delayedRender={false}
            messageBarType={MessageBarType.error}
          >
            Username or password incorrect!
          </MessageBar>
        )}

        <TextField label="Email" onChange={(_, value) => setUserName(value)} />

        <TextField
          label="Password"
          type="password"
          onChange={(_, value) => setPassword(value)}
        />

        <Stack horizontalAlign="center" styles={{ root: { paddingTop: 20 } }}>
          {isLoading ? (
            <Spinner label="Loading..." />
          ) : (
            <PrimaryButton
              style={{ width: 200 }}
              text="Login"
              onClick={login}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginPage;
