import { FormDropdown } from "@d4b/fluent-ui";
import {
  Checkbox,
  IconButton,
  IDropdownOption,
  IDropdownProps,
  Label,
  Stack,
} from "@fluentui/react";
import { useQueryClient } from "react-query";

type Props = {
  name: string;
  formState: any;
  choices: any[];
  onChange?: any;
  value: any;
  label: string;
  required?: boolean;
  choiceKey: string;
  choiceText: string;
  visible?: boolean;
  queriesToInvalidate?: any[];
  multiSelect?: boolean;
  getEntryText?: (i: any) => string;
};
export const Selector = ({
  name,
  formState,
  choices,
  onChange,
  value,
  label,
  required = false,
  choiceKey,
  choiceText,
  visible = true,
  queriesToInvalidate,
  multiSelect = false,
  getEntryText
}: Props) => {
  const appQueryClient = useQueryClient();

  const onRenderOption = (option?: IDropdownOption): JSX.Element => {
    return (
      <Stack horizontal>
        <span>{option?.text}</span>
      </Stack>
    );
  };

  const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
    const option = options
      ? options[0]
      : { text: "Unknown", data: { icon: "Question" } };
    return (
      <Stack horizontal verticalAlign="center">
        <span>{option?.text}</span>
      </Stack>
    );
  };

  const onRenderLabel = (props?: IDropdownProps): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <Label>{props?.label}</Label>

        {queriesToInvalidate ? (
          <IconButton
            iconProps={{ iconName: "Refresh" }}
            title="Refresh"
            ariaLabel="Refresh"
            styles={{ root: { marginBottom: -3 } }}
            onClick={() =>
              appQueryClient.invalidateQueries(queriesToInvalidate)
            }
          />
        ) : (
          <IconButton
            iconProps={{ iconName: "Info" }}
            title="Info"
            ariaLabel="Info"
            styles={{ root: { marginBottom: -3 } }}
          />
        )}
      </Stack>
    );
  };

  return (
    <FormDropdown
      required={required}
      name={name}
      label={label}
      aria-autocomplete="none"
      formState={formState}
      defaultValue={value}
      defaultSelectedKey={formState.values[name] || ""}
      key={value}
      onRenderOption={onRenderOption}
      onRenderLabel={onRenderLabel}
      onRenderTitle={!multiSelect ? onRenderTitle : undefined}
      onChange={onChange}
      visible={visible}
      multiSelect={multiSelect}
      options={choices?.map((i: any) => ({
        key: i[choiceKey],
        text: getEntryText ? getEntryText(i) :`${i[choiceText]}`,
        data: i,
        isSelected: i[choiceKey] === value,
      }))}
    />
  );
};

type ExpandedSelectorProps = {
  options: any[];
  choiceText: string;
  choiceKey: string;
  name: string;
  formState: any;
  label: string;
};

export const ExpandedSelector = ({
  options,
  choiceText,
  choiceKey,
  name,
  formState,
  label,
}: ExpandedSelectorProps) => {
  const { errors, values, setErrors, setValues } = formState;

  const changeSelectedCols = (colKey: string, isCheked?: boolean) => {
    const localSelectedCols: string[] = [];
    options.map((i) =>
      i[choiceKey] === colKey ? (i.addedToList = isCheked) : null
    );
    options.forEach((o) =>
      o.addedToList ? localSelectedCols.push(o[choiceKey]) : null
    );

    const s = localSelectedCols.join(";");
    var newValues = {};
    newValues = { ...values, [name]: s || "" };

    if (errors[name] && setErrors) setErrors({ ...errors, [name]: undefined });
    newValues && setValues(newValues);
  };
  return (
    <>
      <Stack styles={{root: {marginBottom: 10}}} horizontal verticalAlign="center">
        <Label>{label}</Label>
      </Stack>
      {options.map((i) => (
        <Checkbox
          styles={{ root: { marginBottom: 15 } }}
          key={`${i[choiceKey]}`}
          label={`${i[choiceText]}`}
          onChange={(
            ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
            isChecked?: boolean
          ) => {
            changeSelectedCols(i[choiceKey], isChecked);
          }}
        />
      ))}
    </>
  );
};
