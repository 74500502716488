import axios from "axios";
import React, { useContext, useState } from "react";
import { fetchAxios, resolveApiUrl, useAxios } from "@d4b/api";
import { useQuery, useQueryClient } from "react-query";
import PartyDetails from "api/rs-core/rs-party/data-elements/PartyDetails";

type Party = Omit<PartyDetails.PartyDetails, "CreatedAt" | "UpdatedAt" | "Type" | "DefaultEmailAddress" | "ListOfAuthentication" | "_links">;

type AuthContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: Party;
  error: Error | null;
  signin: (username: string, password: string, callback: any) => void;
  signout: (_: any) => void;
};

export const PARTY_ANONYMOUS: Party = {
  PartyId: "Anonymous",
  Name: "Anonymous",
};

export const PARTY_ADMIN: Party = {
  PartyId: "Admin",
  Name: "Sytem Administrator",
};

const AuthContext = React.createContext<AuthContextType>({
  error: null,
  isAuthenticated: false,
  isLoading: true,
  user: PARTY_ANONYMOUS,
  signin: (_user: string, _pass: string, _: any) => {},
  signout: (_: any) => {},
});

export const ProvideAuth = ({ children }: any) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext<AuthContextType>(AuthContext);

export const useProvideAuth = () => {
  const appQueryClient = useQueryClient();
  const axiosApi = useAxios();
  const [currentParty, setCurrentParty] =
    React.useState<Party>(PARTY_ANONYMOUS);

  const { data: myDetails = PARTY_ANONYMOUS } = useQuery<any, Error>(
    "My:Details",
    () => fetchAxios<any>(axiosApi, resolveApiUrl() + "/my", "PartyDetails")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  React.useEffect(() => {
    if (currentParty === myDetails) return;
    setCurrentParty(myDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParty, myDetails]);

  return {
    error,
    user: currentParty,
    isLoading,
    isAuthenticated: currentParty && currentParty.PartyId !== "Anonymous",
    signin: (username: string, password: string, cb: any) => {
      setIsLoading(true);
      axios
        .post(resolveApiUrl() + "/login", { username, password })
        .then(({ data: { token } }) => {
          setIsLoading(false);
          if (token) {
            localStorage.setItem("token", token);
            appQueryClient.invalidateQueries("My:Details");
          } else {
            console.warn("Expected token in server reply");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
          return false;
        });

      cb();
    },
    signout: (cb: any) => {
      localStorage.removeItem("token");
      appQueryClient.invalidateQueries("My:Details");
      setCurrentParty(PARTY_ANONYMOUS);
      cb();
    },
  };
};
