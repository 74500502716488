import Axios, { AxiosInstance } from "axios";
import React from "react";

export const AxiosContext =
  React.createContext<AxiosInstance | undefined>(undefined);

export function AxiosProvider({ children }: React.PropsWithChildren<unknown>) {
  // const token = localStorage.getItem("token");

  const axios = React.useMemo(() => {
    const axios = Axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    axios.interceptors.request.use((config) => {
      // Read token for anywhere, in this case directly from localStorage
      const token = localStorage.getItem("token");
      if (token && config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    return axios;
  }, []);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
}

export function useAxios() {
  return React.useContext(AxiosContext);
}
export default AxiosProvider;
