import { getAbsoluteSourcePath } from "@d4b/dev-tools/utils";
import {
  CommandButton,
  DefaultButton,
  IIconProps,
  IPanelProps,
  IRenderFunction,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  useFormState,
  ErrorMessageBar,
} from "@d4b/fluent-ui";
import React, { CSSProperties, useEffect } from "react";
import { useAppContext } from "lib";
import { RSErrorType } from "@d4b/rs-core";
import { useCommand } from "@d4b/api";

type PanelButtonProps<T, C> = {
  children?: React.ReactNode;
  dataElement: T;
  debug?: boolean;
  formState: useFormState<C>;
  initFromState?: () => any;
  linkName: string;
  showInvalidLink?: boolean;
  buttonStyle?: CSSProperties;
  actionButtonText: string;
  buttonText: string;
  showIcon?: boolean;
  buttonIcon?: string;
  buttonIconOnly?: boolean;
  panelTitle?: string;
  navigationContent?: React.ReactNode;
  onCollectErrors?: (errors: any, values?: C) => void;
  onTransformValues?: (current: C) => any;
  onServerReply?: (onServerReply: any) => void;
  customWidth?: string;
  displayForm?: boolean;
  openedFromLink?: boolean;
  hardClose?: boolean;
  isBlocking?: boolean;
  loadExternalResources?: React.Dispatch<React.SetStateAction<boolean>>;
  panelUid?: string;
  relativePath?: string;
  successMessage?: string;
};

export const PanelButton = <T, C>({
  dataElement,
  showInvalidLink = true,
  buttonStyle,
  initFromState,
  formState,
  children,
  linkName,
  onCollectErrors,
  actionButtonText,
  buttonText,
  buttonIcon,
  panelTitle,
  navigationContent,
  customWidth,
  showIcon = true,
  buttonIconOnly = false,
  onTransformValues,
  displayForm = true,
  openedFromLink = false,
  onServerReply,
  hardClose = false,
  debug = false,
  isBlocking = false,
  loadExternalResources,
  panelUid,
  relativePath,
  successMessage = "Record saved successfully",
}: PanelButtonProps<T, C>) => {
  const { setFlashMessage, ws, isDevelopmentEnvironment } = useAppContext();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  useEffect(() => {
    if (!openedFromLink) return;
    openPanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedFromLink]);

  const [rsError, setRSError] = React.useState<RSErrorType | false>();
  const {
    isSuccess,
    data: serverReply,
    isLoading,
    onSubmit,
    reset,
  } = useCommand(dataElement, linkName, (e: any) => {
    setRSError(e);
    reset();
  });
  const isValidLink = true;
  // let process: any = undefined;

  const onRenderNavigationContent: IRenderFunction<IPanelProps> =
    React.useCallback(
      (props, defaultRender) => (
        <>
          {defaultRender!(props)}
          {navigationContent && navigationContent}
          {panelUid && isDevelopmentEnvironment && ws && (
            <Text style={{ marginTop: 5, marginRight: 10 }}>
              <a
                href={`vscode://file/${getAbsoluteSourcePath(ws)}${relativePath}`}
              >
                {panelUid}
              </a>
            </Text>
          )}
        </>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [navigationContent, panelUid, relativePath]
    );

  const { setValues, setErrors, cleanValues, values } = formState;

  useEffect(() => {
    if (!dataElement || !initFromState) return;
    const loadValues = initFromState();
    setValues(loadValues);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataElement]);

  useEffect(() => {
    if (!isSuccess || !serverReply) return;
    dismissPanel();
    setFlashMessage(successMessage);
    onServerReply && onServerReply(serverReply);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  useEffect(() => {
    if (!hardClose) return;
    dismissPanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hardClose]);

  const onSubmitForm = (): void => {
    let errors: any = {};
    onCollectErrors && onCollectErrors(errors);
    setErrors && setErrors(errors);
    if (errors["rs:Form"]) {
      setRSError({
        errorMessage: "Form error",
        errorMessageDetails: errors["rs:Form"],
      });
      return;
    }
    if (JSON.stringify(errors) !== "{}") {
      console.log("onSubmitForm", errors);
      return;
    }

    const valuesTransformed = onTransformValues
      ? onTransformValues(cleanValues() as any)
      : cleanValues();

    onSubmit(valuesTransformed);
  };

  const iconProps: IIconProps = {
    iconName: buttonIcon,
    styles: { root: { fontSize: 17 } },
  };

  return (
    <>
      {dataElement && !isValidLink && showInvalidLink && (
        <span style={{ color: "red" }}>Invalid link '{linkName}'</span>
      )}
      {showIcon && (
        <CommandButton
          style={buttonStyle}
          styles={{ icon: { display: buttonIcon ? "" : "none" } }}
          text={buttonIconOnly ? undefined : buttonText}
          onClick={() => {
            openPanel();
            if (loadExternalResources) loadExternalResources(true);
          }}
          disabled={!dataElement || !isValidLink || isLoading}
          iconProps={iconProps}
        />
      )}
      {isOpen && (
        <>
          <Panel
            headerText={
              panelTitle && panelTitle !== "" ? panelTitle : undefined
            }
            isOpen={isOpen}
            type={PanelType.custom}
            customWidth={customWidth}
            onDismiss={dismissPanel}
            onRenderHeader={
              navigationContent || panelUid
                ? onRenderNavigationContent
                : undefined
            }
            closeButtonAriaLabel="Close"
            isBlocking={isBlocking}
            style={{ marginTop: 48 }}
            styles={{
              commands: { zIndex: 1 },
            }}
          >
            <Stack style={{ marginTop: 20 }}>
              {/* {JSON.stringify({commandError,isError,isLoading})} */}
              <ErrorMessageBar error={rsError} onDismiss={()=>setRSError(undefined)} />
              <form style={{ width: "100%" }} onSubmit={onSubmitForm}>
                {React.Children.toArray(children).map((child) => {
                  if (!child) return null;
                  const type = typeof (child as any).type;
                  return type === "function"
                    ? React.cloneElement(child as React.ReactElement, {
                        formState,
                      })
                    : React.cloneElement(child as React.ReactElement);
                })}

                <DefaultButton
                  styles={{
                    root: { position: "absolute", bottom: 20, right: 20 },
                  }}
                  text="Cancel"
                  onClick={() => {
                    dismissPanel();
                    if (initFromState) setValues(initFromState());
                    setRSError(false);
                  }}
                />
                {displayForm ? (
                  <>
                    <Stack
                      style={{ position: "absolute", bottom: 20 }}
                      horizontal
                    >
                      <PrimaryButton
                        style={{ marginRight: 5 }}
                        disabled={isLoading || !dataElement}
                        text={actionButtonText}
                        onClick={onSubmitForm}
                      />

                      {isLoading && (
                        <Spinner
                          ariaLive="assertive"
                          labelPosition="right"
                          size={SpinnerSize.small}
                          label="Wait..."
                        />
                      )}
                    </Stack>
                  </>
                ) : (
                  ""
                )}
              </form>
            </Stack>
            {debug && (
              <Stack>
                <Text>Debug information</Text>
                <pre>{JSON.stringify({ values }, null, 2)}</pre>
              </Stack>
            )}
          </Panel>
        </>
      )}
    </>
  );
};

export default PanelButton;
