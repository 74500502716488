import { UrlParams, useOperation } from "@d4b/api";
import { useReplicationHomeOperation } from "@lmd/Admin/api";
import { Operations as ReplicationHomeOperations } from "@lmd/Admin/ReplicationHome";
import MyReplicationAccounts from "api/Admin/data-elements/MyReplicationAccounts";
import ReplicationAccountDetails from "api/Admin/data-elements/ReplicationAccountDetails";
import { UseQueryResult } from "react-query";
import { Operations } from ".";
import { useAccountList } from "./useAccountList";

type AccountsEntry = MyReplicationAccounts.MyReplicationAccountsEntry;
type AccountDetails = ReplicationAccountDetails.ReplicationAccountDetails;
type useAccountDetailsResult = UseQueryResult<AccountDetails, any>;

export const useAccountDetails = (
  account_id: string | undefined
): useAccountDetailsResult => {
  const { error: error1, data: accountEntries } = useAccountList(account_id);

  const account = accountEntries && accountEntries[0];
  // Use the Account details link only on the 1st account
  const result = useOperation<AccountDetails>(
    account,
    Operations["ReplicationAccount:Details"],
    account?.ReplicationAccountId
  ) as useAccountDetailsResult;

  if (error1)
    return { error: error1, data: undefined } as useAccountDetailsResult;

  if (accountEntries && accountEntries?.length > 1)
    return { error: "Multiple accounts found" } as useAccountDetailsResult;

  return result;
};

export const useMyAccountsOperation = (account_id?: string) => {
  const searchParams: UrlParams | undefined = account_id
    ? ({ ReplicationAccountId: account_id } as any)
    : undefined;
  return useReplicationHomeOperation<AccountsEntry[]>(
    ReplicationHomeOperations["MyReplicationAccount:List"],
    searchParams
  );
};
