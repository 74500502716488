import { useQuery, useQueryClient } from "react-query";
import { OperationEntry } from "./Operation";
import { UrlParams } from "./RSTypes";
import { LinkEntry } from "@d4b/rs-core";
import { getLinks, useAxios } from ".";
import { fetchAxios } from "./fetchAxios";

export type useOperationOptions = {
  handleInvalidOperation?: (de: any, operation: OperationEntry) => boolean;
};

export function useOperation<T>(
  de: any,
  operation: OperationEntry,
  key?: string | undefined,
  searchParams?: UrlParams,
  options?: useOperationOptions,
  disabled?: boolean
) {
  const axios = useAxios();
  const appQueryClient = useQueryClient();
  const { link: linkName, dataElement: dataElementName = "" } = operation;
  if (!linkName) {
    throw new Error("An operation must have a link");
  }

  const links = getLinks(de);
  const link: LinkEntry = links[linkName];

  if (de && links && !link) {
    if (!options?.handleInvalidOperation)
      throw new Error(
        "Link " +
        linkName +
        " not found on operation. Valid links: " +
        JSON.stringify(links)
      );
    if (!options.handleInvalidOperation(de, operation)) {
      throw new Error("Link " + linkName + " not found on operation ");
    }
  }

  let url = link?.url;
  let searchParam = new URLSearchParams(searchParams);
  if (url && searchParam?.toString()) {
    const linkUrl = new URL(link.url);
    searchParam.sort();
    if (linkUrl.search !== "") {
      throw Error("TODO: Merge seach params");
    }
    url = `${link.url}?${searchParam.toString()}`;
  }

  return {
    ...useQuery<T>(
      [linkName, key, searchParam?.toString()],
      () => fetchAxios<any>(axios, url, dataElementName, operation.isList),
      { enabled: !disabled && !!link?.url, staleTime: 100 }
    ),
    refreshLink: () => appQueryClient.invalidateQueries([operation.link])
  };
}
