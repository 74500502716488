import { IColumnReorderOptions } from "@fluentui/react";
import { ISortableColumn, useLocalStorage } from "@d4b/fluent-ui";
import { useEffect, useState } from "react";

type ICustomizeColumnsView = {
  fields: any[];
  elementInStorage: string;
};
export type IColEntryChecked = {
  colKey: string;
  isCheked?: boolean;
};

export type IColEntryVisible = {
  key: string;
  visible: boolean;
};

export const useCustomizeColumnsView = ({
  fields,
  elementInStorage,
}: ICustomizeColumnsView) => {
  const [sortedColumns, setSortedColumns] = useLocalStorage(
    elementInStorage,
    localStorage.getItem(elementInStorage)
  );

  const [recheckNewCols, setRecheckNewCols] = useState<boolean>(true);
  const [updateCols, setUpdateCols] = useState<IColEntryChecked | undefined>();
  const [columns, setColumns] = useState<ISortableColumn<any>[]>([]);
  const [selectFields, setSelectFields] = useState<string>();

  useEffect(() => {
    if (!sortedColumns || !fields || !recheckNewCols) return;

    let tempSortedCols: IColEntryVisible[] = JSON.parse(sortedColumns);
    fields.forEach((col, k) => {
      let findCol = tempSortedCols.find((tempCol) => tempCol.key === col.Name);
      if (!findCol)
        tempSortedCols.push({
          key: col.Name,
          visible: true,
        });
    });
    setSortedColumns(JSON.stringify(tempSortedCols));
    //setRecheckNewCols(false);
  }, [
    setSortedColumns,
    recheckNewCols,
    sortedColumns,
    fields,
    setRecheckNewCols,
  ]);

  useEffect(() => {
    if ((sortedColumns && JSON.parse(sortedColumns).length) || !fields)
      return;

    let tempSortedCols: IColEntryVisible[] = [];
    fields.forEach((col, k) => {
      if(col?.Name)
      tempSortedCols.push({
        key: col.Name,
        visible: true,
      });
    });

    setSortedColumns(JSON.stringify(tempSortedCols));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumns, fields]);

  // TODO:
  // function guessSalesOrderLink(SalesForceOrderId: string): string {
  //   return `https://xxxxxxx/lightning/r/Opportunity/${SalesForceOrderId}/view`;
  // }

  useEffect(() => {
    if (!sortedColumns || !fields) return;
    const parsedSortedColumns: IColEntryVisible[] = JSON.parse(sortedColumns);
    let columnsTemp: ISortableColumn<any>[] = [];

    fields.forEach((col, k) => {
      let order = sortedColumns
        ? parsedSortedColumns.findIndex((a: any) => {
            return a.key === col.Name;
          })
        : k;
      if (parsedSortedColumns[order] && parsedSortedColumns[order].visible) {
        let obj = {
          key: col.Name,
          name: col.Name,
          fieldName: col.Name,
          minWidth: 90,
          maxWidth: 200,
          isResizable: true,
          data: { order: order },
        } as any;
        // console.log(col);
        if (col.Type === "datetime")
          obj.onRender = (item: any) => (
            <span>
              {item[col.Name]
                ? new Date(item[col.Name] + "Z").toLocaleString()
                : ""}
            </span>
          );
        if (col.Type === "date")
          obj.onRender = (item: any) => (
            <span>
              {item[col.Name]
                ? new Date(item[col.Name] + "Z").toLocaleDateString()
                : ""}
            </span>
          );
        if (col.Name === "_Error")
          obj.onRender = (item: any) => (
            <span>
              {typeof item._Error === "string"
                ? item._Error
                : JSON.stringify(item._Error)}
            </span>
          );
        if (col.Name.startsWith("_")) obj.maxWidth = 100;
        // if (col.Name === "_AquireTime") obj.maxWidth = 150;
        if (col.Name === "_AquireRowNum" || col.Name === "_WriteRowNum")
          obj.maxWidth = 40;
        // if (col.Name === "_DataSourceName") obj.maxWidth = 150;
        if (col.Name === "_Action") obj.maxWidth = 70;
        if (col.Name === "_Status") obj.maxWidth = 70;
        if (col.Name === "_DataSourceName" || col.Name === "_AquireRowNum")
          obj.customSort = [{field: "_DataSourceName", direction: "asc"}, {field: "_AquireRowNum", direction: "asc"}];
        // TODO:
        // if (col.Name === "SalesForceOrderId")
        //   obj.onRender = (item: any) => (
        //     <Link
        //       href={guessSalesOrderLink(item.SalesForceOrderId)}
        //       target="_blank"
        //     >
        //       {item.SalesForceOrderId}
        //     </Link>
        //   );

        columnsTemp.push(obj);
      }
    });

    // console.log("TODO:","Sort?");
    columnsTemp.sort((a: any, b: any) => a.data.order - b.data.order);

    const sCols: string[] = [];
    columnsTemp.forEach((c) => (c.fieldName ? sCols.push(c.fieldName) : null));
    if (sCols.length)
      setSelectFields(
        [
          ...sCols,
          "_Error",
          "_Status",
          "_Action",
          "_AquireId",
          "_AquireRowNum",
          "_DataSourceId",
        ].join(",")
      );

    setColumns(columnsTemp);
  }, [sortedColumns, fields, elementInStorage]);

  useEffect(() => {
    if (!updateCols) return;
    const parsedSortedColumns: IColEntryVisible[] = JSON.parse(
      sortedColumns || "{}"
    );

    parsedSortedColumns?.map((a) =>
      a.key === updateCols.colKey
        ? (a.visible = updateCols.isCheked ? true : false)
        : ""
    );

    setSortedColumns(JSON.stringify(parsedSortedColumns));
    setUpdateCols(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCols]);

  const _handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
    const parsedSortedColumns: IColEntryVisible[] = JSON.parse(
      sortedColumns || "{}"
    );
    const realdraggedIndex = parsedSortedColumns.findIndex(
      (a) => a.key === columns[draggedIndex].key
    );
    const realtargetIndex = parsedSortedColumns.findIndex(
      (a) => a.key === columns[targetIndex].key
    );

    const draggedSortedItems = parsedSortedColumns[realdraggedIndex];
    parsedSortedColumns.splice(realdraggedIndex, 1);
    parsedSortedColumns.splice(realtargetIndex, 0, draggedSortedItems);
    setSortedColumns(JSON.stringify(parsedSortedColumns));
  };

  function _getColumnReorderOptions(): IColumnReorderOptions {
    return {
      frozenColumnCountFromStart: parseInt("0", 10),
      frozenColumnCountFromEnd: parseInt("0", 10),
      handleColumnReorder: _handleColumnReorder,
    };
  }

  return {
    columns,
    sortedColumns,
    selectFields,
    setSortedColumns: (el: any) => setSortedColumns(el),
    setColumns: (cols: ISortableColumn<any>[]) => setColumns(cols),
    setUpdateCols: (col: IColEntryChecked | undefined) => setUpdateCols(col),
    _getColumnReorderOptions: _getColumnReorderOptions,
  };
};

export default useCustomizeColumnsView;
