import { Spinner, Stack } from "@fluentui/react";
import { ErrorPage, LoginPage, useLocalStorage } from "@d4b/fluent-ui";
import { LmdAppRoutes } from "LmdAppRoutes";

import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppHeader, AppNavs, useAppContext } from "lib";

export const AppRoutes: React.FC = () => {
  const { isLoading, loadingText, isAuthenticated, ws } = useAppContext();
  const [toggleMenu, setToggleMenu] = useLocalStorage("toggleMenu", false);

  return isAuthenticated && !isLoading ? (
    <Router>
      <div className="wrapper">
        <Helmet
          title={`LMD ${ws?.version}`}
          titleTemplate={`%s | LMD ${ws?.version}`}
        />
        <div
          className="header"
          style={{ position: "fixed", width: "100%", zIndex: 2 }}
        >
          <AppHeader />
        </div>
        <div className="main">
          {isAuthenticated && (
            <AppNavs toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
          )}

          <Stack
            styles={{
              root: {
                margin: 0,
                padding: 0,
                width: "100%",
                marginLeft: toggleMenu ? 48 : 240,
                marginTop: 48,
                overflowX: "auto",
              },
            }}
          >
            <Suspense
              fallback={
                <Spinner
                  label="Loading page"
                  styles={{ root: { marginTop: 25 } }}
                />
              }
            >
              <Switch>
                <Route path="/login">
                  <LoginPage />
                </Route>

                {/* TODO: change the component name in the generator(maybe depend on package name) */}
                <LmdAppRoutes />

                <Route path="*">
                  <ErrorPage
                    title="Oups.."
                    message="The resource you have requested is not found. If the
                      problem doesn't go away, please contact support."
                  />
                </Route>
              </Switch>
            </Suspense>
          </Stack>
        </div>
      </div>
    </Router>
  ) : (
    <div>
      <Router>
        {isLoading ? (
          <Spinner label={loadingText} styles={{ root: { marginTop: 25 } }} />
        ) : (
          <LoginPage />
        )}
      </Router>
    </div>
  );
};
