import {
  CommandButton,
  ContextualMenu,
  FontSizes,
  IButtonStyles,
  IContextualMenuItem,
  ISelectableOption,
  Text,
} from "@fluentui/react";
import React, { useEffect } from "react";
import { UseUrlParams } from "@d4b/fluent-ui";

type StandardFiltersProps = {
  filterParams?: UseUrlParams;
  sectionTitle?: boolean;
};
export const StandardFilters = ({
  filterParams,
  sectionTitle = false,
}: StandardFiltersProps) => {
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const linkRef = React.useRef(null);
  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      setShowContextualMenu(true);
    },
    []
  );
  const onHideContextualMenu = React.useCallback(() => {
    setShowContextualMenu(false);
  }, []);
  const [selectedPredifinedFilter, setSelectedPredifinedFilter] =
    React.useState<ISelectableOption>();
    
  useEffect(() => {
    if (filterParams?.options.filters)
      setSelectedPredifinedFilter(filterParams.options.filters[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!filterParams || filterParams?.options.filters?.length === 1) return null;
  const onSelectPredifinedFilter = (item: any) => {
    setSelectedPredifinedFilter(item);

    const extraParamsNew = item.filter;
    const orderBy = filterParams?.extraParams.orderBy;
    if (orderBy) extraParamsNew.orderBy = orderBy;

    if (extraParamsNew !== filterParams?.extraParams)
      filterParams?.setExtraParams(extraParamsNew);
  };
  const menuItems: IContextualMenuItem[] = [];

  filterParams?.options.filters?.forEach((filter) => {
    menuItems.push({
      key: filter.key,
      text: filter.text,
      onClick: () => {
        filterParams.setCurrentPage(1);
        onSelectPredifinedFilter(filter);
      },
      onRenderContent: (menu) => (
        <Text
          style={{
            paddingLeft: 8,
            fontWeight:
              selectedPredifinedFilter?.text === menu.item?.text ? 600 : 400,
          }}
        >
          {menu.item?.text}
        </Text>
      ),
    });
  });

  const styles: IButtonStyles = sectionTitle
    ? {
        root: { marginLeft: -10, marginTop: 10 },
        label: { fontWeight: 600, fontSize: FontSizes.mediumPlus },
        flexContainer: { flexDirection: "row-reverse" },
      }
    : {};

  return (
    <>
      <CommandButton
        styles={styles}
        text={selectedPredifinedFilter?.text}
        iconProps={{
          iconName: "Filter",
        }}
        elementRef={linkRef}
        onClick={onShowContextualMenu}
      />
      <ContextualMenu
        items={menuItems}
        hidden={!showContextualMenu}
        target={linkRef}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
      />
    </>
  );
};
