import { CommandButton } from "@fluentui/react";
import { useEffect, useState } from "react";

type AutoRefreshButtonProps = {
  text: string;
  timeOut: number; // in seconds
  action: any;
};

export const AutoRefreshButton = ({
  text,
  timeOut,
  action,
}: AutoRefreshButtonProps) => {
  const [restartTimer, setRestartTimer] = useState(timeOut);
  useEffect(() => {
    if (restartTimer !== 0) {
      const timer = setTimeout(() => {
        setRestartTimer((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setRestartTimer(timeOut);
      return action;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restartTimer]);

  return (
    <CommandButton
      text={`${text} in ${restartTimer}s`}
      onClick={() => {
        setRestartTimer(timeOut);
        return action;
      }}
      iconProps={{ iconName: "Refresh" }}
    />
  );
};
