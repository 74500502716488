import React from "react";
import { PrivateRoute } from "@d4b/fluent-ui";

        import { ProjectsRoutes } from "./ProjectsRoutes";
        

const FeatureProjects = {
    
ProjectOverviewPage: React.lazy(() => import("@lmd/Projects/views/ProjectOverviewPage")),


ProjectDatasourcesPage: React.lazy(() => import("@lmd/Projects/DataSource/views/ProjectDatasourcesPage")),


DataSourceDetailsPage: React.lazy(() => import("@lmd/Projects/DataSource/views/DataSourceDetailsPage")),


DataSourceMetaObjectDetailsPage: React.lazy(() => import("@lmd/Projects/DataSource/MetaObject/views/DataSourceMetaObjectDetailsPage")),


ProjectDatasourceObjectPage: React.lazy(() => import("@lmd/Projects/DataSource/Object/views/ProjectDatasourceObjectPage")),


ProjectAgentsPage: React.lazy(() => import("@lmd/Projects/Agents/views/ProjectAgentsPage")),


ProjectAgentConfigPage: React.lazy(() => import("@lmd/Projects/Agents/views/ProjectAgentConfigPage")),


ProjectAgentTransmissionsPage: React.lazy(() => import("@lmd/Projects/Agents/views/ProjectAgentTransmissionsPage")),


ProjectLinksPage: React.lazy(() => import("@lmd/Projects/Links/views/ProjectLinksPage")),


ProjectLinkDetailsPage: React.lazy(() => import("@lmd/Projects/Links/views/ProjectLinkDetailsPage")),


ProjectObjectsPage: React.lazy(() => import("@lmd/Projects/Objects/views/ProjectObjectsPage")),


ProjectDataSourceObjectsPage: React.lazy(() => import("@lmd/Projects/Objects/views/ProjectDataSourceObjectsPage")),


ReplicationObjectBrowsePage: React.lazy(() => import("@lmd/Projects/Objects/views/ReplicationObjectBrowsePage")),


ObjectSchemaDesignerPage: React.lazy(() => import("@lmd/Projects/Objects/views/ObjectSchemaDesignerPage")),


};

export const ProjectsAppRoutes = () => (
    <>
        
            <PrivateRoute exact path={ProjectsRoutes.toProjectOverviewPage()}>
                <FeatureProjects.ProjectOverviewPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toProjectDatasourcesPage()}>
                <FeatureProjects.ProjectDatasourcesPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toDataSourceDetailsPage()}>
                <FeatureProjects.DataSourceDetailsPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toDataSourceMetaObjectDetailsPage()}>
                <FeatureProjects.DataSourceMetaObjectDetailsPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toProjectDatasourceObjectPage()}>
                <FeatureProjects.ProjectDatasourceObjectPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toProjectAgentsPage()}>
                <FeatureProjects.ProjectAgentsPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toProjectAgentConfigPage()}>
                <FeatureProjects.ProjectAgentConfigPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toProjectAgentTransmissionsPage()}>
                <FeatureProjects.ProjectAgentTransmissionsPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toProjectLinksPage()}>
                <FeatureProjects.ProjectLinksPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toProjectLinkDetailsPage()}>
                <FeatureProjects.ProjectLinkDetailsPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toProjectObjectsPage()}>
                <FeatureProjects.ProjectObjectsPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toProjectDataSourceObjectsPage()}>
                <FeatureProjects.ProjectDataSourceObjectsPage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toReplicationObjectBrowsePage()}>
                <FeatureProjects.ReplicationObjectBrowsePage />
            </PrivateRoute>
            
            <PrivateRoute exact path={ProjectsRoutes.toObjectSchemaDesignerPage()}>
                <FeatureProjects.ObjectSchemaDesignerPage />
            </PrivateRoute>
            
    </>
);