
import { UrlParams } from "@d4b/api";
import { Operations } from "@lmd/Admin/ReplicationHome";
import { useReplicationHomeOperation } from "@lmd/Admin/api";
import MyReplicationAccounts from "api/Admin/data-elements/MyReplicationAccounts";

type AccountsEntry = MyReplicationAccounts.MyReplicationAccountsEntry;
export const useAccountList = (account_id?: string) => {
    const searchParams:UrlParams|undefined = account_id ? {ReplicationAccountId: account_id} as any : undefined;
    //const searchParams = account_id ? new URLSearchParams(`ReplicationAccountId=${account_id}`) : undefined;
    return useReplicationHomeOperation<AccountsEntry[]>(Operations["ReplicationAccount:All"], searchParams);
}