import { useAuth } from "@d4b/fluent-ui";
import { Route, Redirect } from "react-router-dom";

export function PrivateRoute({ children, ...rest }: any) {
  let auth = useAuth();
  if(auth.isLoading) return null;
  return (
    <Route
      {...rest}
      render={({ location } : any) => auth.isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location }
          }} />
      )} />
  );
}
