import React from "react";
import { TextField } from "@fluentui/react";
import { FormFieldTextProps } from "./FormFields";

export const FormFileUploadField = (props: FormFieldTextProps<any>) => {
  const componentRef = React.useRef<any>();
  const { formState, name, onKeyDown, style } = props;
  let propsOverride = props;

  if (formState && name) {
    const { errors, values, setValues } = formState;
    const { addField } = formState;
    addField(name);
    propsOverride = {
      style: {
        cursor: "pointer",
        ...style
      },
      ...props,
      // onChange: formState.onChange,
      onChange: (e: any) => {
        const { name }: any = e.target;
        if (!name) return;
        if (e.target && e.target.files) {
          const file = e.target.files[0];
          const newValues = {
            ...values,
            ["file:" + name]: file,
          };
          setValues(newValues);
        }
      },

      onKeyDown,
      errorMessage: errors[name],
      value: formState.values[name],
    };
  }
  return (
    <TextField
      {...propsOverride}
      required
      componentRef={componentRef}
      type="file"
      borderless
      // styles={getStyles}
      styles={{
        wrapper: { cursor: "pointer" },
      }}
    />
  );
};
// const onRenderDescription = (props: ITextFieldProps): JSX.Element => {
//   return null;
//   console.log("onRenderDescription", props);
//   return (
//     <Text variant="small" styles={descriptionStyles}>
//       {props.description}xxx
//     </Text>
//   );
// };
