import { Stack, Text } from "@fluentui/react";
import { PageHeader } from "@d4b/fluent-ui";

type Props = {
  title?: string;
  message?: string;
};

export const ErrorPage = ({ title, message }: Props) => (
  <Stack>
    <PageHeader pageTitle={title} pageUid="P009" relativePath="lib\views\ErrorPage.tsx" />
    <Stack style={{ marginLeft: 10, marginRight: 10 }}>
      <Text>
        {message}
      </Text>
    </Stack>
  </Stack>
);
