import { IStackStyles, Stack, Text } from "@fluentui/react";
import { theme } from "App";
import { ReactNode, useState } from "react";

type DetailsCardProps = {
  width?: number;
  height?: number;
  title?: string;
  headerRight?: ReactNode;
  headerTop?: ReactNode;
  headerLeft?: ReactNode;
  children?: ReactNode;
  onClick?: () => void;
};

export const DetailsCard = ({
  width,
  height,
  title,
  children,
  headerRight,
  headerTop,
  headerLeft,
  onClick
}: DetailsCardProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const cardStyles: IStackStyles = {
    root: {
      boxShadow: hovered ? theme.effects.elevation16 : theme.effects.elevation8,
      width,
      height,
      cursor: "pointer"
    },
  };

  const headerStyles: IStackStyles = {
    root: {
      padding: theme.spacing.s1,
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
    },
  };

  const contentStyles: IStackStyles = {
    root: {
      padding: 20,
    },
  };

  return (
    <Stack styles={cardStyles} onClick={onClick} onMouseEnter={()=> setHovered(true)} onMouseLeave={()=> setHovered(false)}>
      {headerTop && headerTop}
      <Stack horizontal verticalAlign="center" styles={headerStyles}>
        {headerLeft}
        <Text
          variant="medium"
          styles={{ root: { fontWeight: 400 } }}
          nowrap
          block
        >
          {title}
        </Text>
        {headerRight && <div style={{ marginLeft: "auto"}}>{headerRight}</div>}
      </Stack>
      <Stack styles={contentStyles}>{children}</Stack>
    </Stack>
  );
};
