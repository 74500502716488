import {
  DetailsListLayoutMode,
  IColumn,
  IColumnReorderOptions,
  IDetailsGroupRenderProps,
  IDetailsListStyleProps,
  IDetailsListStyles,
  IDetailsRowProps,
  IGroup,
  IListProps,
  IObjectWithKey,
  IRenderFunction,
  ISelection,
  IStyleFunctionOrObject,
  SelectionMode,
  ShimmeredDetailsList,
} from "@fluentui/react";

import { useRef } from "react";

export const shimmeredDetailsListProps: IListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

type Props = {
  items: any[];
  columns?: IColumn[];
  groups?: IGroup[]
  setKey?: string;
  layoutMode?: DetailsListLayoutMode;
  selectionMode?: SelectionMode;
  selection?: ISelection<IObjectWithKey>;
  ariaLabelForShimmer?: string;
  ariaLabelForGrid?: string;
  enableShimmer?: boolean;
  onActiveItemChanged?: any;
  groupProps?: IDetailsGroupRenderProps;
  onRenderRow?: IRenderFunction<IDetailsRowProps>;
  columnReorderOptions?: IColumnReorderOptions;
  detailsListStyles?: IStyleFunctionOrObject<IDetailsListStyleProps, IDetailsListStyles>
};

export const ResponsiveDetailsList = ({
  items = [],
  columns,
  groups,
  setKey = "set",
  layoutMode,
  selectionMode,
  selection,
  ariaLabelForShimmer,
  ariaLabelForGrid,
  enableShimmer = false,
  onActiveItemChanged,
  groupProps,
  onRenderRow,
  columnReorderOptions,
  detailsListStyles
}: Props) => {
  const ref = useRef<any>();
  return (
    <ShimmeredDetailsList
      componentRef={ref}
      items={items}
      columns={columns}
      groups={groups}
      setKey={setKey}
      layoutMode={layoutMode || DetailsListLayoutMode.justified}
      selectionMode={selectionMode || SelectionMode.single}
      selection={selection}
      detailsListStyles={detailsListStyles || {
        root: {
          marginLeft: -10,
          marginRight: -10,
          height: `calc((var(--vh, 1vh) * 100) - ${ref.current?._root?.current?.offsetTop}px)`,
        },
      }}
      ariaLabelForShimmer={ariaLabelForShimmer}
      ariaLabelForGrid={ariaLabelForGrid}
      onActiveItemChanged={onActiveItemChanged}
      onRenderRow={onRenderRow}
      groupProps={groupProps}
      columnReorderOptions={columnReorderOptions}
      enableShimmer={enableShimmer}
      listProps={shimmeredDetailsListProps}
    />
  );
};
