import {
  makeSortableColumns,
  ResponsiveDetailsList,
  SectionPager,
  useUrlParams,
} from "@d4b/fluent-ui";
import { useOperation } from "@d4b/api";
import { IColumn, Selection, Stack } from "@fluentui/react";
import {
  Operations,
} from "@lmd/Admin/Account";
import ReplicationAccountDetails from "api/Admin/data-elements/ReplicationAccountDetails";
import React from "react";

type RemoteAccountEntry = any;
export type Reply = any;

type Props = {
  pageName: string;
  account?: ReplicationAccountDetails.ReplicationAccountDetails;
  setSelectedRemoteAccountEntry: React.Dispatch<
    React.SetStateAction<RemoteAccountEntry | undefined>
  >;
};

export const RemoteAccountList = ({
  account,
  pageName,
  setSelectedRemoteAccountEntry,
}: Props) => {
  const { data: remoteAccountsReply, refreshLink } = useOperation<Reply>(
    account,
    Operations.RemoteAccounts
  );

  const filterParams = useUrlParams({
    pageName,
    dataListName: "RemoteAccountList",
    defaultFilterKey: "0",
    filterOptions: [
      {
        key: "Name",
        text: "Name",
      },
    ],
  });

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedRemoteAccountEntry(
        selection.getSelection()[0] as RemoteAccountEntry
      );
    },
  });

  const columns: IColumn[] = makeSortableColumns<RemoteAccountEntry>(
    [
      {
        name: "Name",
        fieldName: "RemoteWebServiceName",
        onRender: (i) => <span>{`${i.RemoteWebServiceName} ${i.RemoteWebServiceLabel}`}</span>,
        minWidth: 100,
        maxWidth: 150,
      },
      {
        name: "Version",
        fieldName: "RemoteWebServiceVersion",
        maxWidth: 50,
      },
      {
        name: "ApiUr",
        fieldName: "RemoteWebServiceApiUrl",
        // TODDO: isUrl: true
      },
    ],
    filterParams
  );
  
  return (
    <Stack style={{ height: "100%", marginTop: 10 }}>
      <SectionPager
        invalidate={refreshLink}
        pagination={remoteAccountsReply?.pagination}
        title="Remote Accounts"
        filterParams={filterParams}
      />
      <ResponsiveDetailsList
        items={remoteAccountsReply?.RemoteAccounts || []}
        columns={columns}
        selection={selection}
      />
    </Stack>
  );
};


