import { Checkbox, CommandButton, Panel, PanelType, Stack } from "@fluentui/react";
import { IColEntryChecked, OnRenderNavigationContent } from "@d4b/fluent-ui";

type CustomizeColumnsViewPanelProps = {
    isOpenModal?: boolean;
    dismissPanel?: () => void;
    sortedColumns: any;
    setSortedColumns: any;
    setUpdateCols: any;
};

export const CustomizeColumnsViewPanel = ({
    isOpenModal,
    dismissPanel,
    sortedColumns,
    setSortedColumns,
    setUpdateCols,
  }: CustomizeColumnsViewPanelProps) => {
    const sortedColumnsAlpha = JSON.parse(sortedColumns);

    sortedColumnsAlpha && sortedColumnsAlpha.sort((a: any, b: any) => a.key?.localeCompare(b.key))
    
    return (
      <Panel
        headerText="Choose columns"
        isOpen={isOpenModal}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
        style={{ marginTop: 48 }}
        styles={{ commands: { zIndex: 2 } }}
        type={PanelType.custom}
        customWidth="480px"
        key="choose_cols"
        onRenderHeader={OnRenderNavigationContent([], "PN040", "@d4b/fluent-ui/components/Panel/CustomizeColumnsViewPanel.tsx")}
      >
        <CommandButton
          style={{ marginLeft: -5 }}
          text="Reset columns"
          iconProps={{ iconName: "Undo" }}
          onClick={() => setSortedColumns(null)}
        />
        <Stack styles={{ root: { marginTop: 48 } }}>
          {sortedColumnsAlpha &&
            sortedColumnsAlpha.map((col: any) => (
              <SortableCheckbox key={col.key} col={col} setUpdateCols={setUpdateCols} />
            ))}
        </Stack>
      </Panel>
    );
  };


  type SortableCheckboxProps = {
    index?: number;
    col: any;
    setUpdateCols: (value: React.SetStateAction<IColEntryChecked | undefined>) => void;
    _handleColumnReorder?: any;
  };
  export const SortableCheckbox = ({ index, col, setUpdateCols, _handleColumnReorder }: SortableCheckboxProps) => {
    return <Stack
      styles={{ root: { marginBottom: 15 } }}
      horizontal
    >
      <Checkbox
        defaultChecked={col.visible}
        label={col.key}
        onChange={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
          isChecked?: boolean
        ) => {
          setUpdateCols({ colKey: col.key, isCheked: isChecked });
        }} />
  
    </Stack>;
  };
  