import {
  CommandButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { useCommand } from "@d4b/api";
import { useQueryClient } from "react-query";

type Props = {
  element: any;
  command: any;
  operation: string;
  queriesToInvalidate: string[];
  elementName?: string;
  setError: Dispatch<SetStateAction<Error | undefined>>;
};

export const RemoveElementButton = ({
  element,
  operation,
  command,
  queriesToInvalidate,
  elementName = "field",
  setError,
}: Props) => {
  const [internError, setInternError] = useState<Error>();
  const setErrorLocal = (error: any) => {
    console.log("Local errors:", error);
    setInternError(error);
    setError(error);
  };
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const appQueryClient = useQueryClient();

  const { onSubmit, isLoading, isSuccess } = useCommand(
    element,
    operation,
    setErrorLocal
  );

  useEffect(() => {
    if (!isSuccess) return;
    queriesToInvalidate.forEach((item) => {
      appQueryClient.invalidateQueries([item]);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, queriesToInvalidate]);

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Confirm",
    subText: `Do you want to remove this ${elementName}?`,
  };

  return (
    <React.Fragment>
      <CommandButton
        text={`Remove ${elementName}`}
        iconProps={{ iconName: "Delete" }}
        disabled={isLoading && !internError}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          if (setError) setError(undefined);
          toggleHideDialog();
        }}
      />
      {isLoading && !internError && (
        <Spinner
          label={`Removing ${elementName}`}
          ariaLive="assertive"
          labelPosition="right"
        />
      )}
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton onClick={() => onSubmit(command)} text="Remove" />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  );
};
