
import { UrlParams, useOperation } from "@d4b/api";
import { Operations } from "@lmd/Admin/WebService/api/Operations";
import { useAppContext } from "lib";

export function useReplicationHomeOperation<T>(op: any, searchParams?: UrlParams) {
  const { ws } = useAppContext();
  const { data: ReplicationHome, error: errorHome, isLoading: isLoadingHome } = useOperation(ws, Operations["Replication:Home"], "");
  const result = useOperation<T>(ReplicationHome, op, "", searchParams);

  return ({
    ...result,
    error: errorHome ? errorHome : result.error,
    isLoading: isLoadingHome ? isLoadingHome : result.isLoading,
  });
};
