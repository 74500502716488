import { useEffect, useState } from "react";

type UsePivotLocalStorageProps = {
  defaultKey?: string;
  currentPage: string;
};

export const usePivotLocalStorage = ({
  currentPage,
  defaultKey = "1",
}: UsePivotLocalStorageProps) => {
  const [switchedKey, setSwitchedKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!switchedKey) return;
    localStorage.setItem(currentPage+":Pivot", switchedKey);
  }, [switchedKey, currentPage]);

  if (!localStorage.getItem(currentPage+":Pivot"))
    localStorage.setItem(currentPage+":Pivot", defaultKey);

  return {
    activePivotKey: switchedKey
      ? switchedKey
      : localStorage.getItem(currentPage+":Pivot"),
    setSwitchedKey: (key: string | undefined) => setSwitchedKey(key),
  };
};

export default usePivotLocalStorage;
