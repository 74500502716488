import React from "react";
import { PrivateRoute } from "@d4b/fluent-ui";

import { AdminRoutes } from "./AdminRoutes";

const FeatureAdmin = {
  AllAccountsPage: React.lazy(
    () => import("@lmd/Admin/Account/views/AllAccountsPage")
  ),

  AccountDetailsPage: React.lazy(
    () => import("@lmd/Admin/Account/views/AccountDetailsPage")
  ),

  PartyListPage: React.lazy(
    () => import("@lmd/Admin/Parties/views/PartyListPage")
  ),

  WebApiPage: React.lazy(() => import("@lmd/Admin/WebApi/views/WebApiPage")),

  RemoteWebServicesPage: React.lazy(
    () => import("@lmd/Admin/RemoteWebServices/views/RemoteWebServicesPage")
  ),

  EventStorePage: React.lazy(
    () => import("@lmd/Admin/EventStore/views/EventStorePage")
  ),

  ApiBrowserPage: React.lazy(
    () => import("@lmd/Admin/ApiBrowser/views/ApiBrowserPage")
  ),

  ApiBrowserLinkPage: React.lazy(
    () => import("@lmd/Admin/ApiBrowser/views/ApiBrowserLinkPage")
  ),

  PackagesFlowPage: React.lazy(
    () => import("@lmd/Admin/Packages/views/PackagesFlowPage")
  ),

  MetaDataPage: React.lazy(
    () => import("@lmd/Admin/FrontendPages/views/MetaDataPage")
  ),

};

export const AdminAppRoutes = () => (
  <>
    <PrivateRoute exact path={AdminRoutes.toAllAccountsPage()}>
      <FeatureAdmin.AllAccountsPage />
    </PrivateRoute>

    <PrivateRoute exact path={AdminRoutes.toAccountDetailsPage()}>
      <FeatureAdmin.AccountDetailsPage />
    </PrivateRoute>

    <PrivateRoute exact path={AdminRoutes.toPartyListPage()}>
      <FeatureAdmin.PartyListPage />
    </PrivateRoute>

    <PrivateRoute exact path={AdminRoutes.toWebApiPage()}>
      <FeatureAdmin.WebApiPage />
    </PrivateRoute>

    <PrivateRoute exact path={AdminRoutes.toEventStorePage()}>
      <FeatureAdmin.EventStorePage />
    </PrivateRoute>

    <PrivateRoute exact path={AdminRoutes.toApiBrowserPage()}>
      <FeatureAdmin.ApiBrowserPage />
    </PrivateRoute>

    <PrivateRoute exact path={AdminRoutes.toApiBrowserLinkPage()}>
      <FeatureAdmin.ApiBrowserLinkPage />
    </PrivateRoute>

    <PrivateRoute exact path={AdminRoutes.toRemoteWebServicesPage()}>
      <FeatureAdmin.RemoteWebServicesPage />
    </PrivateRoute>

    <PrivateRoute exact path={AdminRoutes.toPackagesFlowPage()}>
      <FeatureAdmin.PackagesFlowPage />
    </PrivateRoute>

    <PrivateRoute exact path={AdminRoutes.toMetaDataPage()}>
      <FeatureAdmin.MetaDataPage />
    </PrivateRoute>
  </>
);
