import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "@fluentui/react";
import { getTheme } from "@fluentui/style-utilities";

import { AppRoutes, BreakpointProvider } from "lib/components";

import "./App.css";
import { AxiosProvider } from "@d4b/api";
import { AppContextProvider } from "lib";
import { ProvideAuth } from "@d4b/fluent-ui";
export const theme = getTheme();
export const themePalette = theme.palette;

export const App: React.FC = () => {
  return (
      <ThemeProvider style={{ height: "inherit" }}>
        <BreakpointProvider>
          <AxiosProvider>
            <AppContextProvider>
              <ProvideAuth>
                <AppRoutes />
              </ProvideAuth>
            </AppContextProvider>
          </AxiosProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </BreakpointProvider>
      </ThemeProvider>
  );
};
