
export const Operations = {

    ReplicationAccountProjects: {
        link: "ReplicationAccount:Projects",
        dataElement: "ReplicationAccountProjects",
    },

    TransmissionPerObject: {
        link: "ReplicationAccount:TransmissionPerObject",
        dataElement: "",
    },

    "ReplicationAccount:DataSourceSubscriptions": {
        link: "ReplicationAccount:DataSourceSubscriptions",
        dataElement: "DataSourceSubscriptions"
    },
    "ReplicationAccount:AddDataSourceSubscription": {
        link: "ReplicationAccount:AddDataSourceSubscription",
        dataElement: ""
    },
    AddRemoteAccount: {
        link: "ReplicationAccount:AddRemoteAccount",
        dataElement: ""
    },
    RemoteAccounts: {
        link: "ReplicationAccount:RemoteAccounts",
        dataElement: ""
    },
    "ReplicationAccount:Details": {
        link: "ReplicationAccount:Details",
        dataElement: "ReplicationAccountDetails",
    },
    "ReplicationAccount:Create": {
        link: "ReplicationAccount:Create",
        dataElement: ""
    }
}