import { OperationEntry, useOperation } from "@d4b/api";
import { FormDropdown } from "@d4b/fluent-ui";
import {
  IconButton,
  IDropdownOption,
  IDropdownProps,
  Label,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

type OperationSelectorProps<T> = {
  name: string;
  label: string;
  formState: any;
  de: any;
  listName: string;
  operation: OperationEntry;
  onChange?: (e: any) => void;
  visible?: boolean;
  getEntryKey: (i: T) => string;
  getEntryText: (i: T) => string;
  onInit?: any;
  operationKey?:string;
};

export function OperationSelector<T>({
  listName,
  de,
  formState,
  label,
  name,
  operation,
  visible = true,
  getEntryText,
  getEntryKey,
  onChange,  
  onInit,
  operationKey,
}: OperationSelectorProps<T>) {
  const { data, isFetching } = useOperation<any>(de, operation,operationKey);
  const appQueryClient = useQueryClient();
  // const [options, setOptions] = useState([]);
  const { [listName]: items = [] } = data || {};
  const [ init, setInit] = useState(false);
  useEffect(() => {
    appQueryClient.invalidateQueries([de?.link,operationKey,""]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[operationKey]); 

  useEffect(() => {
    if (!onInit || init || !items?.length) return;
    const find = items.find(
      (item: T) => getEntryKey(item) === formState.values[name]
    );
    onInit(find);
    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  //console.log(operation.link,operationKey,options)
  const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
    const option = options
      ? options[0]
      : { text: "Unknown", data: { icon: "Question" } };
    return (
      <Stack horizontal verticalAlign="center">
        <span>{option?.text}</span>
      </Stack>
    );
  };

  const onRenderLabel = (props?: IDropdownProps): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <Label>{props?.label}</Label>
        {isFetching ? (
          <Spinner size={SpinnerSize.small} />
        ) : (
          <IconButton
            iconProps={{ iconName: "Info" }}
            title="Info"
            ariaLabel="Info"
            styles={{ root: { marginBottom: -3 } }}
          />
        )}
      </Stack>
    );
  };

  const options = items.map((i: T) => ({
    key: getEntryKey(i),
    text: getEntryText(i),
    data: i,
  }));

  return (
    <FormDropdown
      disabled={isFetching}
      name={name}
      label={label}
      formState={formState}
      onRenderLabel={onRenderLabel}
      onRenderTitle={onRenderTitle}
      onChange={onChange}
      defaultValue={formState.values[name] || ""}
      defaultSelectedKey={formState.values[name] || ""}
      key={formState.values[name] || ""}
      visible={visible}
      options={options || []}
    />
  );
}

export default OperationSelector;
