import { CommandButton } from "@fluentui/react"
import { CSSProperties } from "react";

type HoverIconButtonProps = {
    id: string;
    style?: CSSProperties;
    iconName?: string; 
    textButton?: string;
    displayed?: boolean;
    onClick?: () => void;
};
export const HoverIconButton = ({id, style = { marginBottom: -15, marginTop: -15 }, iconName, textButton, displayed, onClick}:HoverIconButtonProps) => {
    return <>{displayed && <CommandButton
    id={id}
    style={style}
    disabled={false}
    iconProps={{
      iconName: iconName,
    }}
    text={textButton}
    onClick={onClick}
  />}</>
}