import WebServiceComponent from "api/rs-core/rs-app/data-elements/WebServiceComponent";
import pkg from "../../../../package.json";

export function getAbsoluteSourcePath(ws: WebServiceComponent.WebServiceComponent) {
  const frontPath = (ws as any).RS_FRONTEND_DIR || ".";
  let rootPath: string = (pkg as any)["rs-cli"]?.root || "src";
  if (!rootPath.endsWith("/"))
    rootPath += "/";
  return `${frontPath}/${rootPath}`;
}
