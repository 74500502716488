import { getAbsoluteSourcePath } from "@d4b/dev-tools/utils";
import {
  IRenderFunction,
  IPanelProps,
  IconButton,
  Text,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { theme } from "App";
import { useAppContext } from "lib";
import React from "react";
import { useQueryClient } from "react-query";

export const OnRenderNavigationContent = (
  queriesToInvalidate: any[],
  panelUid?: string,
  relativePath?: string,
  isLoading?: boolean
): IRenderFunction<IPanelProps> => {
  const { ws, isDevelopmentEnvironment } = useAppContext();
  const appQueryClient = useQueryClient();

  // let process: any = undefined;
  return React.useCallback(
    (props, defaultRender) => (
      <>
        {defaultRender!(props)}
        {panelUid && isDevelopmentEnvironment && ws && (
          <Text style={{ marginTop: 5, marginRight: 10 }}>
            <a
              href={`vscode://file/${getAbsoluteSourcePath(
                ws as any
              )}${relativePath}`}
            >
              {panelUid}
            </a>
          </Text>
        )}
        {isLoading && <Spinner
          ariaLive="assertive"
          labelPosition="right"
          size={SpinnerSize.small}
          label="Wait..."
        />}
        {queriesToInvalidate.length !== 0 && (
          <IconButton
            style={{ height: 32, width: 32 }}
            iconProps={{
              iconName: "refresh",
              style: { color: theme.palette.neutralDark, fontSize: 14 },
            }}
            onClick={() => {
              queriesToInvalidate.forEach((item) => {
                appQueryClient.invalidateQueries([item]);
              });
            }}
          />
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panelUid, relativePath, queriesToInvalidate]
  );
};
