import { PageMetaData } from "lib/meta-utils";
import { getAbsoluteSourcePath } from "@d4b/dev-tools/utils";
import { useAppContext } from "lib";

type Props = {
  pageMetaData?: PageMetaData;
  pageUid?: string;
  componentPath?: string;
  componentName?: string;
};
 
export const PageMetaDataViewer = ({
  pageMetaData,
  pageUid,
  componentPath,
  componentName,
}: Props) => {
  const { ws, isDevelopmentEnvironment, hasRole } = useAppContext();
  if (!ws) return null;

  const showViewer =
    (isDevelopmentEnvironment && (pageMetaData?.pageUid || pageUid)) || hasRole("Administrator");

  if (!showViewer) return null;

  const vsCodeHRef = `vscode://file/${getAbsoluteSourcePath(ws)}${
    pageMetaData?.componentPath || componentPath
  }${
    pageMetaData?.componentName || componentName
      ? pageMetaData?.componentName || componentName + ".tsx"
      : ""
  }`;

  return (
    <span style={{ position: "absolute", top: 54, right: 20, zIndex: 1 }}>
      <a href={vsCodeHRef}>{pageMetaData?.pageUid || pageUid}</a>
    </span>
  );
};
export default PageMetaDataViewer;
