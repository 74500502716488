import * as React from "react";
import { Text, ActionButton, CommandButton } from "@fluentui/react";
import { UseUrlParams } from "@d4b/fluent-ui";

type PagerProps = {
  pagination?: any;
  filterParams?: UseUrlParams;
  invalidate?: (reload?: boolean) => void;
};

export const Pager = ({ pagination, filterParams, invalidate }: PagerProps) => {
  const buttonStyle = { marginRight: 5 };
  const {
    page,
    pageCount,
    hasNextPage,
    hasPrevPage,
    total = 0,
  } = pagination || {};
  return pagination ? (
    <>
      <Text style={{ marginLeft: 20, marginRight: 25 }}>
        {total > 0 && `Total: ${total} `}&nbsp;&nbsp;
        {pageCount > 0
          ? `Page ${page || "--"} of ${pageCount || "--"} `
          : `No rows`}
      </Text>
      <CommandButton
        text="First"
        disabled={!hasPrevPage}
        onClick={() => filterParams?.setCurrentPage(1)}
      />
      <ActionButton
        disabled={!hasPrevPage}
        onClick={() => filterParams?.setCurrentPage(page - 1)}
        style={buttonStyle}
        iconProps={{ iconName: "Previous" }}
      />
      <ActionButton
        disabled={!hasNextPage}
        onClick={() => filterParams?.setCurrentPage(page + 1)}
        style={buttonStyle}
        iconProps={{ iconName: "Next" }}
      />
      <CommandButton
        styles={{ flexContainer: { flexDirection: "row-reverse" } }}
        text="Last"
        disabled={!hasNextPage}
        onClick={() => filterParams?.setCurrentPage(pageCount)}
      />
      {invalidate && (
        <ActionButton
          // disabled={!hasPrevPage}
          onClick={() => invalidate(true)}
          style={buttonStyle}
          iconProps={{ iconName: "Refresh" }}
        />
      )}
    </>
  ) : null;
};
