import React, { CSSProperties, useState } from "react";

import { Link, MessageBar, MessageBarType, Stack, Text } from "@fluentui/react";
import { RSErrorType } from "@d4b/rs-core";
import { useAppContext } from "lib";
import { isError } from "@d4b/api";

type ErrorMessageBarProps = {
  error?: RSErrorType | Error | false | null;
  hideTime?: boolean;
  style?: CSSProperties;
  actions?: JSX.Element;
  extraAction?: any;
  onDismiss?: any;
};

type ErrorEntryProps = {
  error: any;
};
export const ErrorEntry = ({ error }: ErrorEntryProps) => {
  const { errorMessage = "Unkown", errorMessageDetails = false, ...rest } = error;

  const extraStr = JSON.stringify(rest || "");

  return (
    <Stack style={{ paddingBottom: 8 }}>
      {errorMessage && (
        <Text variant="small">
          {typeof errorMessage == "string"
            ? errorMessage
            : JSON.stringify(errorMessage)}
        </Text>
      )}
      {errorMessageDetails && (
        <Text style={{ marginLeft: 8 }} variant="smallPlus">
          {typeof errorMessageDetails == "string"
            ? errorMessageDetails
            : JSON.stringify(errorMessageDetails)}
        </Text>
      )}
      {extraStr && extraStr !== "" && (
        <Text style={{ marginLeft: 8 }} variant="xSmall">
          <pre style={{ padding: 0, margin: 0 }}>{extraStr}</pre>
        </Text>
      )}
    </Stack>
  );
};

type ErrorListProps = {
  error: any;
};
export const ErrorList = ({ error }: ErrorListProps) => {
  if (!error) return null;

  const {
    errorMessage = "",
    errorMessageDetails = "",
    ListOfErrors: errors = [],
  } = error;
  // if ((listOfErrors.length = 0)) return null;

  return (
    <Stack>
      {errorMessage && errorMessage !== "" && (
        <Text style={{ marginBottom: 8 }} variant="mediumPlus">
          <strong>{errorMessage}</strong>
        </Text>
      )}
      {errorMessageDetails !== "" && (
        <Text style={{ marginBottom: 8 }}>{typeof errorMessageDetails == "string" ? errorMessageDetails : JSON.stringify(errorMessageDetails)}</Text>
      )}
      {errors.reverse().map((error: any, i: number) => (
        <ErrorEntry key={i} error={error} />
      ))}
    </Stack>
  );
};

export const ErrorMessageBar = ({
  error = false,
  hideTime = false,
  style,
  actions,
  extraAction,
  onDismiss
}: ErrorMessageBarProps) => {
  const { myDetails } = useAppContext();
  const [errorDate] = useState(new Date());
  const [closed, setClosed] = useState(false);
  // Reformat error
  if(typeof error === "string" && error !== "" && !closed) {
        error = { errorMessage: error};
  } else if (!isError(error) || closed) return null;
  const { errorFields = false, Error } = (error as any) || {};

  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline
      onDismiss={onDismiss || (() => setClosed(true))}
      actions={actions}
      styles={{ root: { ...(style as any) } }}
    >
      <Stack>
        {Error ? <ErrorList error={Error} /> : <ErrorList error={error} />}
        {/* <pre>{JSON.stringify({Error,error},null,2)}</pre> */}

        {errorFields && errorFields.length > 0 && (
          <ul>
            {errorFields.map((v: any) => (
              <li key={v}>{v}</li>
            ))}
          </ul>
        )}
        {extraAction && <Link onClick={extraAction}>Reset filters!</Link>}
        {!hideTime && errorDate && (
          <Text variant="tiny">{errorDate.toISOString()}</Text>
        )}
        <Text variant="tiny">{myDetails.Name}</Text>
        
      </Stack>
    </MessageBar>
  );
};
