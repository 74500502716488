import { Stack, DefaultEffects, Panel, PanelType, Link } from "@fluentui/react";
import { useAppContext } from "lib";
import { OnRenderNavigationContent } from "@d4b/fluent-ui/components";

type Props = {
  isOpen: boolean;
  dismissPanel: any;
};
export const AppMenuPanel = ({ isOpen, dismissPanel }: Props) => {
  const { hasRole, ws } = useAppContext();

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={dismissPanel}
      type={PanelType.customNear}
      customWidth="320px"
      closeButtonAriaLabel="Close"
      headerText="Apps"
      onRenderHeader={OnRenderNavigationContent([], "PN038", "lib/components/AppMenuPanel.tsx")}
    >
      <Stack style={{ marginTop: 20 }}>
        <Link
          href={ws?.RS_APP_URL}
          target="_blank"
          styles={{
            root: {
              width: "100%",
              padding: 10,
              marginBottom: 10,
              color: "#000",
              boxShadow: DefaultEffects.elevation4,
            },
          }}
          key="1"
        >
          {ws?.name} {ws?.version}
        </Link>

        {hasRole("Administrator") ? (
          <>
            <Link
              href={ws?.RS_ADMIN_URL}
              target="_blank"
              styles={{
                root: {
                  width: "100%",
                  padding: 10,
                  marginBottom: 10,
                  color: "#000",
                  boxShadow: DefaultEffects.elevation4,
                },
              }}
              key="2"
            >
              RS Admin
            </Link>
            <Link
              href={ws?.RS_APP_API_URL + "/api"}
              target="_blank"
              styles={{
                root: {
                  width: "100%",
                  padding: 10,
                  marginBottom: 10,
                  color: "#000",
                  boxShadow: DefaultEffects.elevation4,
                },
              }}
              key="3"
            >
              RS Config
            </Link>
            <Link
              href={ws?.RS_APP_API_URL + "/login"}
              target="_blank"
              styles={{
                root: {
                  width: "100%",
                  padding: 10,
                  marginBottom: 10,
                  color: "#000",
                  boxShadow: DefaultEffects.elevation4,
                },
              }}
              key="3"
            >
              API Login
            </Link>
          </>
        ) : (
          ""
        )}
      </Stack>
    </Panel>
  );
};
