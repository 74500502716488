/**
 * Projects Routes
 */

export type toDataSourceMetaObjectDetailsProps = {
  account_id?: string;
  project_id?: string;
  datasource_id?: string;
  object_id?: string;
};

export const ProjectsRoutes = {
  toProjectOverviewPage: (account_id?: string, project_id?: string): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/overview`
      : "/admin/accounts/:account_id/projects/:project_id/overview",

  toProjectDatasourcesPage: (
    account_id?: string,
    project_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/datasources`
      : "/admin/accounts/:account_id/projects/:project_id/datasources",

  toDataSourceDetailsPage: (
    account_id?: string,
    project_id?: string,
    datasource_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/datasource/${datasource_id}`
      : "/admin/accounts/:account_id/projects/:project_id/datasource/:datasource_id",

      

  toDataSourceMetaObjectDetailsPage: (
    account_id?: string,
    project_id?: string,
    datasource_id?: string,
    object_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/datasource/${datasource_id}/metaobjects/${object_id}`
      : "/admin/accounts/:account_id/projects/:project_id/datasource/:datasource_id/metaobjects/:object_id",
  toDataSourceMetaObjectDetailsPage2: (
    params: toDataSourceMetaObjectDetailsProps
  ): string =>
    params.account_id
      ? `/admin/accounts/${params.account_id}/projects/${params.project_id}/datasource/${params.datasource_id}/metaobjects/${params.object_id}`
      : "/admin/accounts/:account_id/projects/:project_id/datasource/:datasource_id/metaobjects/:object_id",


  toProjectDatasourceObjectPage: (
    account_id?: string,
    project_id?: string,
    datasource_id?: string,
    object_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/datasource/${datasource_id}/objects/${object_id}`
      : "/admin/accounts/:account_id/projects/:project_id/datasource/:datasource_id/objects/:object_id",

  toProjectAgentsPage: (account_id?: string, project_id?: string): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/agents`
      : "/admin/accounts/:account_id/projects/:project_id/agents",

  toProjectAgentConfigPage: (
    account_id?: string,
    project_id?: string,
    agent_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/agent/${agent_id}/config`
      : "/admin/accounts/:account_id/projects/:project_id/agent/:agent_id/config",

  toProjectAgentTransmissionsPage: (
    account_id?: string,
    project_id?: string,
    agent_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/agent/${agent_id}/details`
      : "/admin/accounts/:account_id/projects/:project_id/agent/:agent_id/details",

  toProjectLinksPage: (account_id?: string, project_id?: string): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/links`
      : "/admin/accounts/:account_id/projects/:project_id/links",

  toProjectLinkDetailsPage: (
    account_id?: string,
    project_id?: string,
    link_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/links/${link_id}`
      : "/admin/accounts/:account_id/projects/:project_id/links/:link_id",

  toProjectObjectsPage: (account_id?: string, project_id?: string): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/objects`
      : "/admin/accounts/:account_id/projects/:project_id/objects",

  toProjectDataSourceObjectsPage: (
    account_id?: string,
    project_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/datasource-objects`
      : "/admin/accounts/:account_id/projects/:project_id/datasource-objects",

  toReplicationObjectBrowsePage: (
    account_id?: string,
    project_id?: string,
    replicationObject_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/objects/${replicationObject_id}/data`
      : "/admin/accounts/:account_id/projects/:project_id/objects/:replicationObject_id/data",

  toObjectSchemaDesignerPage: (
    account_id?: string,
    project_id?: string,
    replicationObject_id?: string
  ): string =>
    account_id
      ? `/admin/accounts/${account_id}/projects/${project_id}/objects/${replicationObject_id}`
      : "/admin/accounts/:account_id/projects/:project_id/objects/:replicationObject_id",
};
