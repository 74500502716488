import { MessageBar, MessageBarType } from "@fluentui/react";
import { useAppContext } from "lib";
import { useEffect } from "react";

type Props = {
  displayTimeout?: number,
}
export const FlashMessageBar = ({displayTimeout = 5000}:Props) => {
  const { flashMessage, setFlashMessage } = useAppContext();

  useEffect(() => {
    if (!flashMessage) return;
    const timer = setTimeout(() => {
      setFlashMessage("");
    }, displayTimeout);
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flashMessage, setFlashMessage]);

  return flashMessage ? (
    <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
      {flashMessage}
    </MessageBar>
  ) : (
    <></>
  );
};
