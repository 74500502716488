import {
  CommandButton,
  INavLink,
  INavLinkGroup,
  IRenderFunction,
  Text,
} from "@fluentui/react";
import { useAppContext } from "lib";
import { AdminRoutes } from "@lmd/Admin/AdminRoutes";
import { ProjectsRoutes } from "@lmd/Projects/ProjectsRoutes";
import { CSSProperties } from "react";
import { themePalette } from "App";
import { useHistory } from "react-router-dom";
import { SideNavBar } from "@d4b/fluent-ui";
import MyReplicationAccounts from "api/Admin/data-elements/MyReplicationAccounts";
import { useOperation } from "@d4b/api";
import ReplicationProjectDetails from "api/Projects/data-elements/ReplicationProjectDetails";

type AccountsEntry = MyReplicationAccounts.MyReplicationAccountsEntry;
type Props = {
  toggleMenu: boolean;
  setToggleMenu: any;
};
export const AppNavs = ({ toggleMenu, setToggleMenu }: Props) => {
  const {
    currentAccount,
    myAccount,
    setCurrentProject,
    hasRole,
    currentProject,
  } = useAppContext();
  const history = useHistory();
  
  const { data: projectDetails } = useOperation<ReplicationProjectDetails.ReplicationProjectDetails>(
    currentProject,
    { link: "ReplicationProject:Details", dataElement: "ReplicationProjectDetails"},
    currentProject ? currentProject.ReplicationProjectId : '',
    {},
    undefined,
    !currentProject
  );

  const customLinkStyle: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  };
  const badgeStyle: CSSProperties = {
    minWidth: 22,
    height: 22,
    lineHeight: "22px",
    fontSize: 12,
    borderRadius: "50%",
    // color: "#FFF",
    // backgroundColor: "#edebe9",
  };

  const onRenderLink: IRenderFunction<INavLink> = (
    link?: INavLink,
    defaultRender?
  ) => {
    if (!defaultRender || !link) return null;
    // console.log(link.data);
    if (link.name === "Home" && link.data) {
      const account = link.data as AccountsEntry;
      return <Text style={{ paddingLeft: 4 }}>{account.ClientPartyName}</Text>;
    }

    if (currentProject) {
      if (link.key?.includes("SubDs")) {
        return (
          <span style={customLinkStyle}>
            <CommandButton
              style={{ paddingLeft: toggleMenu ? 0 : 40 }}
              text={toggleMenu ? `${link.name[0] || ""}${link.name[1] || ""}` : link.name}
            />
          </span>
        );
      }
      if (link.key === "D" + currentProject.ReplicationProjectId) {
        return (
          <span style={customLinkStyle}>
            <CommandButton
              iconProps={{ iconName: "Database" }}
              style={{ paddingLeft: toggleMenu ? 0 : 20 }}
              text={toggleMenu ? "" : link.name}
            />
            <span style={badgeStyle}>{currentProject.NumberOfDataSources}</span>
          </span>
        );
      }
      if (link.key === "L" + currentProject.ReplicationProjectId) {
        return (
          <span style={customLinkStyle}>
            <CommandButton
              iconProps={{ iconName: "LinkedDatabase" }}
              style={{ paddingLeft: toggleMenu ? 0 : 20 }}
              text={toggleMenu ? "" : link.name}
            />
            <span style={badgeStyle}>{currentProject.NumberOfLinks}</span>
          </span>
        );
      }
      if (link.key === "A" + currentProject.ReplicationProjectId) {
        return (
          <span style={customLinkStyle}>
            <CommandButton
              iconProps={{ iconName: "DatabaseSync" }}
              style={{ paddingLeft: toggleMenu ? 0 : 20 }}
              text={toggleMenu ? "" : link.name}
            />
            <span style={badgeStyle}>{currentProject.NumberOfAgents}</span>
          </span>
        );
      }
      if (link.key === "S" + currentProject.ReplicationProjectId) {
        return (
          <span style={customLinkStyle}>
            <CommandButton
              iconProps={{ iconName: "EngineeringGroup" }}
              style={{ paddingLeft: toggleMenu ? 0 : 20 }}
              text={toggleMenu ? "" : link.name}
            />
            {/* <span style={badgeStyle}>99</span> */}
          </span>
        );
      }
      if (link.key === "DS" + currentProject.ReplicationProjectId) {
        return (
          <span style={customLinkStyle}>
            <CommandButton
              iconProps={{ iconName: "PageData" }}
              style={{ paddingLeft: toggleMenu ? 0 : 20 }}
              text={toggleMenu ? "" : link.name}
            />
            {/* <span style={badgeStyle}>99</span> */}
          </span>
        );
      }
    }

    if (link.name === "My projects" || link.name === "Project details") {
      return (
        <span
          style={{
            width: "100%",
            textAlign: "left",
            marginLeft: -10,
            paddingLeft: 15,
            cursor: "default",
          }}
        >
          <Text
            variant="medium"
            style={{ color: themePalette.neutralSecondary }}
          >
            {link.name}
          </Text>
        </span>
      );
    }

    return defaultRender(link);
  };

  const AdminLinks: INavLinkGroup = {
    name: toggleMenu ? "" : "Admin",
    collapseByDefault: true,
    links: [
      {
        name: toggleMenu ? "" : "All Accounts",
        icon: "AccountBrowser",
        url: AdminRoutes.toAllAccountsPage(),
        key: "All Accounts",
        title: "",
        onClick: (e: any) => {
          e && e.preventDefault();
          history.push(AdminRoutes.toAllAccountsPage());
        },
        forceAnchor: true
      },
      {
        name: toggleMenu ? "" : "Parties",
        icon: "AddToShoppingList",
        url: AdminRoutes.toPartyListPage(),
        key: "Parties",
        title: "",
        onClick: (e: any) => {
          e && e.preventDefault();
          history.push(AdminRoutes.toPartyListPage());
        },
        forceAnchor: true,
      },
      {
        name: toggleMenu ? "" : "WebApi",
        key: "WebApi",
        icon: "AzureAPIManagement",
        url: AdminRoutes.toWebApiPage(),
        title: "",
        onClick: (e: any) => {
          e && e.preventDefault();
          history.push(AdminRoutes.toWebApiPage());
        },
        forceAnchor: true,
      },
      {
        name: toggleMenu ? "" : "RemoteWebServices",
        key: "RemoteWebServices",
        icon: "AzureAPIManagement",
        url: AdminRoutes.toRemoteWebServicesPage(),
        title: "",
        onClick: (e: any) => {
          e && e.preventDefault();
          history.push(AdminRoutes.toRemoteWebServicesPage());
        },
        forceAnchor: true,
      },
      {
        name: toggleMenu ? "" : "Event store",
        key: "eventstore",
        icon: "Event",
        url: AdminRoutes.toEventStorePage(),
        title: "",
        onClick: (e: any) => {
          e && e.preventDefault();
          history.push(AdminRoutes.toEventStorePage());
        },
        forceAnchor: true,
      },
      {
        name: toggleMenu ? "" : "API Browser",
        key: "APIBrowser",
        icon: "BrowserTab",
        url: AdminRoutes.toApiBrowserPage(),
        title: "",
        onClick: (e: any) => {
          e && e.preventDefault();
          history.push(AdminRoutes.toApiBrowserPage());
        },
        forceAnchor: true,
      },
      {
        name: toggleMenu ? "" : "Packages Flow",
        key: "packages-flow",
        icon: "Packages",
        url: AdminRoutes.toPackagesFlowPage(),
        title: "",
        onClick: (e: any) => {
          e && e.preventDefault();
          history.push(AdminRoutes.toPackagesFlowPage());
        },
        forceAnchor: true,
      },
      {
        name: toggleMenu ? "" : "Frontend Pages",
        key: "frontend-pages",
        icon: "ReopenPages",
        url: AdminRoutes.toMetaDataPage(),
        title: "",
        onClick: (e: any) => {
          e && e.preventDefault();
          history.push(AdminRoutes.toMetaDataPage());
        },
        forceAnchor: true,
      }
    ],
  };

  const navLinkGroups: INavLinkGroup[] = [];
  const groupNavLinks: INavLinkGroup = {
    links: [
      {
        name: toggleMenu ? "" : "Home",
        url: "/",
        icon: "Home",
        key: "key1",
        title: "",
        data: currentAccount,
        onClick: (e: any) => {
          e && e.preventDefault();
          history.push("/");
        },
        forceAnchor: true,
      },
    ],
  };

  groupNavLinks.links.push({
    name: toggleMenu ? "" : "My projects",
    url: "",
    disabled: true,
  });

  myAccount?.Projects?.forEach((p) => {
    let plink = {
      name: toggleMenu ? "" : p.Name,
      key: p.ReplicationProjectId,
      icon: "ProjectCollection",
      url: ProjectsRoutes.toProjectOverviewPage(
        p.ReplicationAccountId,
        p.ReplicationProjectId
      ),
      title: "",
      onClick: (e: any) => {
        e && e.preventDefault();
        setCurrentProject(p);
        localStorage.setItem("currentProject", p.ReplicationProjectId);
        history.push(
          ProjectsRoutes.toProjectOverviewPage(
            p.ReplicationAccountId,
            p.ReplicationProjectId
          )
        );
      },
      forceAnchor: true,
    };
    groupNavLinks?.links.push(plink);
    if (p.ReplicationProjectId === projectDetails?.ReplicationProjectId) {
      GetProjectLinks(projectDetails, history).forEach((l) =>
        groupNavLinks?.links.push(l)
      );
    }
  });

  navLinkGroups.push(groupNavLinks);
  if (hasRole("Administrator")) {
    navLinkGroups.push(AdminLinks);
  }

  return (
    <SideNavBar
      toggleMenu={toggleMenu}
      setToggleMenu={setToggleMenu}
      navLinkGroupe={navLinkGroups}
      onRenderLink={onRenderLink}
    />
  );
};

export const GetProjectLinks = (
  currentProject: ReplicationProjectDetails.ReplicationProjectDetails,
  history: any
): INavLink[] => {
  let projectLinks: INavLink[] = [];

  projectLinks.push({
    name: "Objects",
    key: "S" + currentProject.ReplicationProjectId,
    //icon: "RowsChild",
    url: ProjectsRoutes.toProjectObjectsPage(
      currentProject.ReplicationAccountId,
      currentProject.ReplicationProjectId
    ),
    title: "",
    onClick: (e: any) => {
      e && e.preventDefault();
      history.push(
        ProjectsRoutes.toProjectObjectsPage(
          currentProject.ReplicationAccountId,
          currentProject.ReplicationProjectId
        )
      );
    },
    forceAnchor: true,
  });

  projectLinks.push({
    name: "Datasource Objects",
    key: "DS" + currentProject.ReplicationProjectId,
    //icon: "RowsChild",
    url: ProjectsRoutes.toProjectDataSourceObjectsPage(
      currentProject.ReplicationAccountId,
      currentProject.ReplicationProjectId
    ),
    title: "",
    onClick: (e: any) => {
      e && e.preventDefault();
      history.push(
        ProjectsRoutes.toProjectDataSourceObjectsPage(
          currentProject.ReplicationAccountId,
          currentProject.ReplicationProjectId
        )
      );
    },
    forceAnchor: true,
  });

  projectLinks.push({
    name: "Datasources",
    key: "D" + currentProject.ReplicationProjectId,
    //icon: "Database",
    url: ProjectsRoutes.toProjectDatasourcesPage(
      currentProject.ReplicationAccountId,
      currentProject.ReplicationProjectId
    ),
    title: "",
    onClick: (e: any) => {
      e && e.preventDefault();
      history.push(
        ProjectsRoutes.toProjectDatasourcesPage(
          currentProject.ReplicationAccountId,
          currentProject.ReplicationProjectId
        )
      );
    },
    forceAnchor: true,
  });
  currentProject.DataSources.forEach( ds => projectLinks.push({
    name: ds.Name,
    key: "SubDs" + ds.ReplicationDataSourceId,
    //icon: "Database",
    url: ProjectsRoutes.toDataSourceDetailsPage(
      currentProject.ReplicationAccountId,
      currentProject.ReplicationProjectId,
      ds.ReplicationDataSourceId
    ),
    title: "",
    onClick: (e: any) => {
      e && e.preventDefault();
      history.push(
        ProjectsRoutes.toDataSourceDetailsPage(
          currentProject.ReplicationAccountId,
          currentProject.ReplicationProjectId,
          ds.ReplicationDataSourceId
        )
      );
    },
    forceAnchor: true,
  }))

  projectLinks.push({
    name: "Links",
    key: "L" + currentProject.ReplicationProjectId,
    //icon: "LinkedDatabase",
    url: ProjectsRoutes.toProjectLinksPage(
      currentProject.ReplicationAccountId,
      currentProject.ReplicationProjectId
    ),
    title: "",
    onClick: (e: any) => {
      e && e.preventDefault();
      history.push(
        ProjectsRoutes.toProjectLinksPage(
          currentProject.ReplicationAccountId,
          currentProject.ReplicationProjectId
        )
      );
    },
    forceAnchor: true,
  });

  projectLinks.push({
    name: "Agents",
    key: "A" + currentProject.ReplicationProjectId,
    //icon: "DatabaseSync",
    url: ProjectsRoutes.toProjectAgentsPage(
      currentProject.ReplicationAccountId,
      currentProject.ReplicationProjectId
    ),
    title: "",
    onClick: (e: any) => {
      e && e.preventDefault();
      history.push(
        ProjectsRoutes.toProjectAgentsPage(
          currentProject.ReplicationAccountId,
          currentProject.ReplicationProjectId
        )
      );
    },
    forceAnchor: true,
  });

  return projectLinks;
};
