import { useProvideAuth } from "@d4b/fluent-ui";
import {
  createSafeContext,
  fetchAxios,
  OperationEntry,
  resolveApiUrl,
  useAxios,
  useOperation,
  useOperationOptions,
  useSafeContext,
} from "@d4b/api";
import {
  Operations as AccountOperations,
} from "@lmd/Admin/Account";
import { Operations as ReplicationHomeOperations } from "@lmd/Admin/ReplicationHome";
import { Operations } from "@lmd/Admin/WebService";
import ReplicationHome from "api/Admin/data-elements/ReplicationHome";
import type WebServiceComponent from "api/rs-core/rs-app/data-elements/WebServiceComponent";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import ReplicationAccountDetails from "api/Admin/data-elements/ReplicationAccountDetails";
import MyReplicationAccounts from "api/Admin/data-elements/MyReplicationAccounts";
import ListOfAllRoles from "api/rs-core/rs-party/data-elements/ListOfAllRoles";
import PartyDetails from "api/rs-core/rs-party/data-elements/PartyDetails";

type ReplicationProjectEntry = ReplicationAccountDetails.Projects;
type AccountDetails = ReplicationAccountDetails.ReplicationAccountDetails;
type AccountsEntry = MyReplicationAccounts.MyReplicationAccountsEntry;
type PartyRoleFull = ListOfAllRoles.ListOfAllRolesEntry;
type Party = Omit<PartyDetails.PartyDetails, "CreatedAt" | "UpdatedAt" | "Type" | "DefaultEmailAddress" | "ListOfAuthentication" | "_links">;


export type IWebService = WebServiceComponent.WebServiceComponent & {
  RS_APP_LABEL: string;
  RS_APP_API_URL: string;
  RS_APP_URL: string;
  RS_ADMIN_URL: string;
};

// export const API_URL = resolveApiUrl();
let AppContext = createSafeContext<any>();
export const useAppContext = () => useSafeContext<IAppContextValue>(AppContext);

type IAppContextValue = {
  myDetails: Party;
  error: Error | null;
  myRoles: PartyRoleFull[];
  ownerRoles: PartyRoleFull[];
  currentOwnerRole: PartyRoleFull | undefined;
  setCurrentOwnerRole: React.Dispatch<
    React.SetStateAction<PartyRoleFull | undefined>
  >;
  // TODO: (Auth) API_URL: string;
  // TODO: (Auth) setAPI_URL: React.Dispatch<React.SetStateAction<string>>;
  flashMessage: string;
  setFlashMessage: React.Dispatch<React.SetStateAction<string>>;
  ws: IWebService | undefined;
  isDevelopmentEnvironment: boolean;
  ReplicationHome: any; // What is this?
  hasRole: (role: string) => boolean;
  findFirstRole: (roleName: string) => PartyRoleFull | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  loadingText: string;
  myAccounts?: AccountsEntry[];
  myAccount?: AccountDetails;
  currentAccount?: AccountsEntry;
  setCurrentAccount: React.Dispatch<
    React.SetStateAction<AccountsEntry | undefined>
  >;

  currentProject?: ReplicationProjectEntry;
  setCurrentProject: React.Dispatch<
    React.SetStateAction<ReplicationProjectEntry | undefined>
  >;
  checkAvailableAccount: (account_id?: string) => boolean;
  isFetchedAccounts: boolean;
  //refetchMyAccounts:any;
};

const checkRole = (myDetails: Party, roles: any, roleName: string): boolean => {
  if (!roles || roles.length === 0) {
    // Allow Admin to have all roles
    if (roleName === "Administrator" && myDetails.PartyId === "Admin")
      return true;
    return false;
  }

  let result = false;
  // eslint-disable-next-line array-callback-return
  roles.map((x: any) => {
    if (x.RoleName === roleName) {
      result = true;
      // eslint-disable-next-line array-callback-return
      return; // Early exit:
    }
  });
  // console.log(roles);
  return result;
};

const findFirstRole = (
  roles: PartyRoleFull[] | undefined,
  roleName: string
): PartyRoleFull | undefined => {
  let result: PartyRoleFull | undefined = undefined;
  if (!roles) return undefined;

  // eslint-disable-next-line array-callback-return
  roles.map((x: PartyRoleFull) => {
    if (x.RoleName === roleName) {
      result = x;
      // eslint-disable-next-line array-callback-return
      return;
    }
  });
  return result;
};

const findRoles = (
  roles: PartyRoleFull[] | undefined,
  roleName: string
): PartyRoleFull[] =>
  !roles ? [] : roles?.filter((x: PartyRoleFull) => x.RoleName === roleName);

export const AppContextProvider = (props: any) => {
  const [flashMessage, setFlashMessage] = useState("");
  const [ownerRoles, setOwnerRoles] = useState<PartyRoleFull[]>([]);
  const [currentOwnerRole, setCurrentOwnerRole] =
    useState<PartyRoleFull | undefined>(undefined);

  const [currentAccount, setCurrentAccount] =
    useState<AccountsEntry | undefined>();

  const [currentProject, setCurrentProject] =
    useState<ReplicationProjectEntry | undefined>(undefined);

  const [loadingText, setLoadingText] = useState("Starting application");

  // TODO: const [API_URL, setAPI_URL] = useState(resolveApiUrl);
  const axios = useAxios();

  const {
    data: ws,
    isLoading: isLoadingWs,
    error = null,
  } = useQuery<IWebService, Error>("WebServiceComponent", () =>
    fetchAxios<IWebService>(axios, resolveApiUrl(), "WebServiceComponent")
  );
  const { user, isLoading: isLoadingAuth, isAuthenticated } = useProvideAuth();

  const partyRoleOptions: useOperationOptions = {
    handleInvalidOperation: (de, op: OperationEntry) => {
      return op.link === "My:Roles";
    },
  };

  const { data: myRoles = [], isLoading: isLoadingRoles } = useOperation<
    PartyRoleFull[]
  >(
    user,
    { link: "My:Roles", dataElement: "ListOfRoles" },
    user.PartyId,
    undefined,
    partyRoleOptions,
    !isLoadingAuth || !isAuthenticated
  );

  const { data: ReplicationHome, isLoading: isLoadingHome } =
    useOperation<ReplicationHome.ReplicationHome>(
      ws,
      Operations["Replication:Home"],
      ws?.component_id,
      undefined,
      {},
      !isAuthenticated || !user
    );

  const { data: myAccounts, isFetched: isFetchedAccounts } = useOperation<
    AccountsEntry[] | undefined
  >(ReplicationHome, ReplicationHomeOperations["MyReplicationAccount:List"]);

  const { data: myAccount } = useOperation<AccountDetails>(
    currentAccount,
    AccountOperations["ReplicationAccount:Details"],
    currentAccount?.ReplicationAccountId
  );

  // myAccount?.ClientPartyName
  useEffect(() => {
    const myOwnerRoles = findRoles(myRoles, "ReplicationAccountOwner");

    if (ownerRoles !== myOwnerRoles) setOwnerRoles(myOwnerRoles);

    if (!currentOwnerRole && myOwnerRoles.length > 0)
      setCurrentOwnerRole(myOwnerRoles[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    // console.log({ isLoadingAuth, isLoadingHome, isLoadingRoles, isLoadingWs });
    if (isLoadingRoles) {
      setLoadingText("Loading current user roles");
      return;
    }
    if (isLoadingAuth) {
      setLoadingText("Loading current user");
      return;
    }

    if (isLoadingHome) {
      setLoadingText("Loading web service " + ws?.name);
      return;
    }

    if (isLoadingWs) {
      setLoadingText("Loading web service");
      return;
    }
    setLoadingText("Done");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAuth, isLoadingWs, isLoadingHome, isLoadingRoles]);

  useEffect(() => {
    if (currentAccount || !myAccounts) return;
    if (localStorage.getItem("currentAccount")) {
      var a = myAccounts?.find(
        (a: any) =>
          a.ReplicationAccountId === localStorage.getItem("currentAccount")
      );
      if (a) setCurrentAccount(a);
      else setCurrentAccount(myAccounts ? myAccounts[0] : undefined);
    } else setCurrentAccount(myAccounts ? myAccounts[0] : undefined);
  }, [currentAccount, myAccounts]);

  useEffect(() => {
    if (currentProject || !myAccount || !localStorage.getItem("currentProject"))
      return;
    let p = myAccount.Projects.find(
      (a: { ReplicationProjectId: string | null }) =>
        a.ReplicationProjectId === localStorage.getItem("currentProject")
    );
    if (p) setCurrentProject(p);
  }, [currentProject, myAccount]);

  const checkAvailableAccount = (account_id?: string): boolean => {
    if (!account_id) return false;
    const foundAccount = myAccounts?.find(
      (a: any) => a.ReplicationAccountId === account_id
    );
    return foundAccount ? true : false;
  };

  const value: IAppContextValue = {
    error,
    flashMessage,
    setFlashMessage,
    ws,
    ReplicationHome,
    myDetails: user as Party,
    myRoles: myRoles || [],
    ownerRoles,
    currentOwnerRole,
    isAuthenticated,
    isLoading: isLoadingHome || isLoadingAuth || isLoadingWs || isLoadingRoles,
    isDevelopmentEnvironment: process?.env?.NODE_ENV === "development",
    loadingText,
    setCurrentOwnerRole,
    hasRole: (roleName: string): boolean =>
      checkRole(user, myRoles || [], roleName),
    findFirstRole: (roleName: string) => findFirstRole(myRoles, roleName),
    myAccounts,
    myAccount,
    currentAccount,
    setCurrentAccount,

    currentProject,
    setCurrentProject,
    checkAvailableAccount,
    isFetchedAccounts,
    // refetchMyAccounts,
  };
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

export default AppContextProvider;
