import { Text } from "@fluentui/react";
import { theme } from "App";

type ISectionTitleProps = {
  title: string;
  styles?: any;
};
export const SectionTitle = ({ title, styles }: ISectionTitleProps) => (
  <Text
    variant="mediumPlus"
    style={{
      color: theme.palette.neutralPrimary,
      width: "50%",
      fontWeight: 600,
      marginTop: 10,
    }}
    styles={styles}
  >
    {title || "-"}
  </Text>
);
