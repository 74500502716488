import { Checkbox } from "@fluentui/react";
export type YorNCheckboxProps = {
  formState: any;
  name: string;
  label: string;
};
export const YorNCheckbox = ({ formState, name, label }: YorNCheckboxProps) => {
  return <Checkbox
    styles={{ root: { marginTop: 15 } }}
    name={name}
    label={label}
    defaultChecked={formState.values[name] === "Y"}
    onChange={(
      ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      formState.setValue(name, checked ? "Y" : "N");
    }}
  />
  };
