
import { LabeledText } from "@d4b/fluent-ui";
import { ILinkStyles, Link, PersonaCoin, PersonaSize } from "@fluentui/react";
import { theme } from "App";

type PunctuatedCardLinkProps = {
  cardStyles: ILinkStyles;
  label: string;
  subLabel: string;
  indice: string;
  keyItem?: string;
  active?: boolean;
  clickEvent?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>
  ) => void;
};
export const PunctuatedCardLink = ({
  cardStyles = {
    root: {
      boxShadow: theme.effects.elevation8,
      width: 180,
      padding: 10,
      position: "relative",
      textAlign: "center",
      marginRight: 20,
      marginLeft: 10,
    },
  },
  label,
  subLabel,
  indice,
  keyItem,
  active,
  clickEvent,
}: PunctuatedCardLinkProps) => {
  return (
    <Link
      styles={cardStyles}
      style={{ border: active ? "2px solid #0078d4" : "", textDecoration: "none" }}
      onClick={clickEvent}
    >
      <PersonaCoin
        style={{ position: "absolute", top: -10, left: -10 }}
        size={1 ? 1 : PersonaSize.size32}
        imageInitials={indice}
        initialsColor={active ? "#0078d4" : "#484644"}
      />
      <LabeledText key={keyItem} label={label} text={subLabel} />
    </Link>
  );
};
