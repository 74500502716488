import {
  CommandButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

type Props = {
  dialogTitle: string;
  dialogSubText?: string;
  commandButtonText: string;
  isLoading: boolean;
  iconName: string;
  onClick: any;
};

export const ConfirmButton = ({
  dialogTitle,
  dialogSubText,
  commandButtonText,
  isLoading,
  iconName,
  onClick,
}: Props) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const dialogContentProps = {
    type: DialogType.normal,
    title: dialogTitle,
    subText: dialogSubText,
  };

  return (
    <>
      <CommandButton
        text={commandButtonText}
        iconProps={{ iconName: iconName }}
        disabled={isLoading}
        onClick={toggleHideDialog}
      />
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              toggleHideDialog();
              onClick();
            }}
            text="Confirm"
          />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
