import * as React from "react";
import { Stack } from "@fluentui/react";
import { AdvancedFilterPanel, SectionTitle, StandardFilters } from "..";
import { UseUrlParams } from "@d4b/fluent-ui";
import { Pager } from "./Pager";

type SectionPagerProps = {
  pagination?: any;
  invalidate?: (reload?: boolean) => void;
  style?: React.CSSProperties;
  title?: string;
  filterParams?: UseUrlParams;
  advancedFilterPanel?: any;
};

export const SectionPager = ({
  pagination, invalidate, style = { marginRight: 10, marginLeft: 10 }, title, filterParams, advancedFilterPanel
}: SectionPagerProps) => {
  const hasTitle: boolean = (title || "") !== "";
  return (
    <>
      <Stack horizontal verticalAlign="center" style={{ ...style }}>
        <Stack.Item grow={3} align="center">
          {title && <SectionTitle title={title} />}
          <StandardFilters
            sectionTitle={!hasTitle}
            filterParams={filterParams} />
          {advancedFilterPanel && advancedFilterPanel}  
        </Stack.Item>
        <Pager
          pagination={pagination}
          filterParams={filterParams}
          invalidate={invalidate} />
      </Stack>
    </>
  );
};
