import { useOperation } from "@d4b/api";
import { IBreadcrumbItem } from "@fluentui/react";
import { useAppContext } from "lib";
import {
  PageDef,
  PageContext,
  PageMetaData,
  PageBreadCrumbEntry,
  apiLinks,
  selectListItem,
} from ".";

export const usePage = (def: PageDef, ctx: PageContext): PageMetaData => {
  //const { apiData } = useApiData(def.api);

  const breadCrumbs: IBreadcrumbItem[] = def.breadCrumbs.map(
    (b: PageBreadCrumbEntry) => {
      const bc = ctx.customBreadCrumbs?.find((c) => c.id === b.id);
      return {
        text: bc ? bc.text : b.text,
        key: b.id,
        onClick: (e: any) => {
          e && e.preventDefault();
          ctx.history.push(bc ? bc.url : b.url);
        },
      };
    }
  );

  return {
    id: def.id,
    pageUid: def.pageUid,
    pageTitle: ctx.pageTitle ? ctx.pageTitle : def.pageTitle,
    instructions: ctx.instructions,
    componentPath: def.componentPath,
    componentName: def.componentName,
    operationsToInvalidate: def.operationsToInvalidate,
    isLoading: ctx.isLoading,
    hasModeView: def.hasModeView,
    breadCrumbs,
    filterParams: def.filterParams,
    apiData: undefined,
  };
};

export default usePage;

type findDeProps = {
  response: any;
  de: string;
  selectListItem: selectListItem;
};
const findDe = ({ response, de, selectListItem }: findDeProps): any => {
  return response && de ? response[de][0] : undefined;
};

export const useApiData = (api?: apiLinks[]): any => {
  const { ws, isAuthenticated } = useAppContext();
  let results: any = {};
  api?.forEach((a, i) => {
    const de = !a.selectListItem
      ? (i === 0
        ? ws
        : results[api[i - 1]?.visitName])
      : findDe({
          response: results[api[i - 1]?.visitName],
          de: api[i - 1]?.de,
          selectListItem: a.selectListItem,
        });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data } = useOperation<any>(
      de,
      { link: a.visit, dataElement: "" },
      ws?.component_id,
      undefined,
      {},
      !isAuthenticated || !de
    );
    results = { ...results, [a.visitName]: data };
  });

  return { apiData: results };
};
