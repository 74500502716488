import * as React from "react";
import { DefaultPalette, getTheme } from "@fluentui/react";

const theme = getTheme();

type useHoverOptions = {
  noCopy?: boolean;
}
export const useHover = (_options?: useHoverOptions) => {
  // const { noCopy = false } = options;
  const [hover, setHover] = React.useState(false);

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const hoverStyle = hover
    ? {
        // opacity: 1,
        transition: "all .2s ease-in-out",
        backgroundColor: theme.semanticColors.buttonBackgroundHovered,
        boxShadow: theme.effects.elevation4,
      }
    : {
        // opacity: 1,
        transition: "all .2s ease-in-out",
        backgroundColor: DefaultPalette.white,
        // boxShadow: DefaultEffects.elevation4,
      };

  const visibleStyle: React.CSSProperties = hover
    ? {
        transition: "all .2s ease-in-out",
        visibility: "visible",
      }
    : {
        transition: "all .2s ease-in-out",
        visibility: "hidden",
      };

  return {
    visibleStyle,
    hoverStyle,
    hoverProps: { onMouseEnter, onMouseLeave },
    hover,
  };
};
