// import * as url from 'url';

const removeTrailingSlashes = (s: string): string => s.replace(/\/$/, '');

let resolvedUrl = '';

export const getFromWindow = (): any =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  window && (<any>window)._env_ ? (<any>window)._env_.RS_APP_API_URL : '';

const getFromLocation = (): string => {
  const location =
    window.location.origin === 'null' || window.location.origin === undefined
      ? ''
      : window.location.origin;
  // eslint-disable-next-line
  return location + '/api';
};

export const resolveApiUrl = (aUrl?: string): string => {
  if (!aUrl && resolvedUrl.length > 0) return resolvedUrl;

  if (aUrl && aUrl.length > 0) {
    resolvedUrl = aUrl;
    return resolvedUrl;
  }
  if (resolvedUrl.length === 0) resolvedUrl = getFromWindow();
  if (resolvedUrl.length === 0) resolvedUrl = getFromLocation();

  // // let process: any = undefined;
  // const overidePort = process?.env?.REACT_APP_API_PORT;

  // if (typeof overidePort === 'string') {
  //   const x = url.parse(resolvedUrl);
  //   resolvedUrl = `${x.protocol}//${x.hostname}:${overidePort}${x.path}`;
  // }
  return removeTrailingSlashes(resolvedUrl);
};

export default resolveApiUrl;
