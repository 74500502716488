import { useAuth, useOutsideClick } from "@d4b/fluent-ui";
import {
  CommandButton,
  DefaultEffects,
  getTheme,
  IButtonStyles,
  IStackStyles,
  ITextStyles,
  Link,
  Persona,
  PersonaSize,
  Stack,
  Text,
} from "@fluentui/react";
import { useAppContext } from "lib";
import React, { createRef } from "react";
import { useHistory } from "react-router-dom";

export const theme = getTheme();
export const themePalette = theme.palette;
const UserDropDownPositionStyles: IStackStyles = {
  root: {
    position: "absolute",
    top: 48,
    right: 0,
    width: 320,
    boxShadow: DefaultEffects.elevation16,
    backgroundColor: "#FFF",
    zIndex: 100,
  },
};
const UserRefButtonStyles: IButtonStyles = {
  root: {
    color: "inherit",
    backgroundColor: "inherit",
    borderRadius: "50%",
    width: 32,
    height: 32,
    borderColor: themePalette.white,
  },
  flexContainer: { justifyContent: "center" },
  rootHovered: { color: themePalette.white },
};
const UserDropDownBlockStyles: IStackStyles = {
  root: {
    zIndex: 999,
    color: themePalette.neutralSecondary,
  },
};
const BigCircleButtonStyles: IButtonStyles = {
  root: {
    color: "inherit",
    width: 80,
    height: 80,
    margin: 20,
    backgroundColor: "inherit",
    borderRadius: "50%",
    borderColor: themePalette.neutralDark,
    fontSize: 24,
  },
  flexContainer: { justifyContent: "center" },
  rootHovered: { color: themePalette.neutralDark },
};
const appTextStyles: ITextStyles = {
  root: { paddingLeft: 10, width: 190 },
};
// const apiTextStyles: ITextStyles = {
//   root: { marginTop: -20, paddingLeft: 10 },
// };
const subTextsStyles: ITextStyles = {
  root: { lineHeight: 24, width: 190 },
};
const signoutStyles: ITextStyles = {
  root: { padding: 10 },
};

type UserPreferencePanelProps = {};

export const UserPreferencePanel = (_: UserPreferencePanelProps) => {
  const [showDropDownMenu, setShowDropDownMenu] = React.useState(false);
  const auth = useAuth();
  const user: any = auth.user;
  const history = useHistory();
  const ref = createRef<HTMLDivElement>();
  useOutsideClick(ref, () => {
    setShowDropDownMenu(false);
  });

  const initials = user.Name.substr(0, 1).toUpperCase();
  function changeShowDropDownMenu() {
    setShowDropDownMenu((prev) => !prev);
  }

  const { myAccounts, currentAccount, setCurrentAccount } = useAppContext();

  return (
    <div
      ref={ref}
      style={{
        width: 48,
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CommandButton
        styles={UserRefButtonStyles}
        text={initials}
        onClick={() => changeShowDropDownMenu()}
      />

      {showDropDownMenu && (
        <Stack styles={UserDropDownPositionStyles}>
          <Stack styles={UserDropDownBlockStyles}>
            <Stack horizontal>
              <Stack.Item grow>
                <Text styles={appTextStyles} variant="medium" nowrap block>
                  LinkMyData
                </Text>
              </Stack.Item>
              <Stack.Item>
                <Link
                  styles={signoutStyles}
                  onClick={() => {
                    console.log("Signout...");
                    auth.signout((_: any) => history.replace("/login"));
                  }}
                >
                  Sign out
                </Link>
              </Stack.Item>
            </Stack>

            <Stack horizontal>
              <Stack.Item>
                <CommandButton styles={BigCircleButtonStyles} text={initials} />
              </Stack.Item>
              <Stack.Item grow align="center">
                <Text
                  styles={subTextsStyles}
                  key={"name"}
                  variant="xLarge"
                  nowrap
                  block
                >
                  {user.Name}
                </Text>
                <Text styles={subTextsStyles} key={"email"} nowrap block>
                  {user.DefaultEmailAddress}
                </Text>
              </Stack.Item>
            </Stack>
            {myAccounts && (
              <Stack
                horizontal
                style={{
                  borderTop: "1px solid #b5b5b5",
                  marginTop: 4,
                  position: "relative",
                }}
              >
                <Text
                  style={{
                    position: "absolute",
                    top: -28,
                    left: "50%",
                    backgroundColor: "white",
                    transform: "translateX(-50%)",
                    width: 104,
                    height: 32,
                    textAlign: "center",
                  }}
                >
                  Accounts
                </Text>
                <Stack.Item style={{ paddingTop: 15, width: "100%" }}>
                  {myAccounts.map((a) => (
                    <Link
                      key={"link" + a.ReplicationAccountId}
                      onClick={() => {
                        setCurrentAccount(a);
                        localStorage.setItem(
                          "currentAccount",
                          a.ReplicationAccountId
                        );
                        setShowDropDownMenu(false);
                        history.push("/");
                      }}
                      style={{
                        backgroundColor:
                          a.ReplicationAccountId ===
                          currentAccount?.ReplicationAccountId
                            ? "#edebe9"
                            : "",
                      }}
                      styles={{
                        root: {
                          paddingLeft: 15,
                          paddingBottom: 5,
                          width: "100%",
                          textDecoration: "none !important",
                        },
                      }}
                    >
                      <Persona
                        text={a.ClientPartyName}
                        size={PersonaSize.size32}
                        imageAlt={a.ClientPartyName}
                        styles={{ root: { marginTop: 10 } }}
                        key={a.ReplicationAccountId}
                      />
                    </Link>
                  ))}
                </Stack.Item>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </div>
  );
};
