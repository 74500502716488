import React from "react";
import { PrivateRoute } from "@d4b/fluent-ui";

        import { HomeRoutes } from "./HomeRoutes";
        

const FeatureHome = {
    
HomePage: React.lazy(() => import("@lmd/Home/views/HomePage")),


};

export const HomeAppRoutes = () => (
    <>
        
            <PrivateRoute exact path={HomeRoutes.toHomePage()}>
                <FeatureHome.HomePage />
            </PrivateRoute>
            
    </>
);