export const Operations = {
    "ReplicationDataSourceType:List" : {
        link: "ReplicationDataSourceType:List",
        dataElement: "DataSourceTypes",
        isList: true,
    },
    "MyReplicationAccount:List": {
        link: "MyReplicationAccount:List",
        dataElement: "Accounts",
    },
    "ReplicationAccount:All": {
        link: "ReplicationAccount:All",
        dataElement: "Accounts",
    },
    "ReplicationAccount:ListFullResult": {
        link: "ReplicationAccount:All",
        dataElement: "",
        isList: false
    }
}