export const Operations = {
    "WebApi:List": {
        link: "WebApi:List",
        dataElement: "ListOfWebApis",
        isList: true,
    },
    "WebApiConfig:List" : {
        link: "WebApiConfig:List",
        dataElement: "", //TODO: Add "WebApiConfigs" 
        isList: true,
    },
    "Replication:Home" : {
        link: "Replication:Home",
        dataElement: "ReplicationHome"
    },
    "My:Details": {
        link: "My:Details",
        dataElement: "PartyDetails",
        isList: false
    }
}