import { CommandButton, IContextualMenuItem } from "@fluentui/react";
import React, { CSSProperties } from "react";

type MoreActionsMenuProps = {
  id: string;
  menuItems: IContextualMenuItem[];
  style?: CSSProperties;
  iconName?: string;
  textButton?: string;
};

export const MoreActionsMenu = ({
  id,
  menuItems,
  style = { marginBottom: -15, marginTop: -15 },
  iconName = "MoreVertical",
  textButton = "",
}: MoreActionsMenuProps) => (
  <CommandButton
    id={id}
    style={style}
    disabled={false}
    iconProps={{ iconName }}
    text={textButton}
    menuProps={{ items: menuItems }}
  />
);
