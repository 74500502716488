/**
 * Admin Routes
 */

export const AdminRoutes = {
  toAllAccountsPage: (): string => "/admin/accounts",

  toAccountDetailsPage: (account_id?: string): string =>
    account_id
      ? `/admin/accounts/${account_id}`
      : "/admin/accounts/:account_id",

  toPartyListPage: (): string => "/admin/partyList",

  toWebApiPage: (): string => "/admin/webapi",

  toRemoteWebServicesPage: (): string => "/admin/remote-web-services",

  toEventStorePage: (): string => "/admin/eventStore",

  toApiBrowserPage: (): string => "/admin/api-browser",

  toApiBrowserLinkPage: (linkName?: string, dataElement?: string): string =>
    linkName
      ? `/admin/api-browser/link?linkName=${linkName}&dataElement=${dataElement}`
      : "/admin/api-browser/link",

  toPackagesFlowPage: (): string => "/admin/packages-flow",

  toMetaDataPage: (): string => "/admin/meta-data-pages"
};
