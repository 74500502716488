import {
  IconButton,
  INavLink,
  INavLinkGroup,
  INavStyles,
  IRenderFunction,
  Nav,
  Stack,
} from "@fluentui/react";
import { themePalette } from "App";

type Props = {
  toggleMenu: boolean;
  setToggleMenu: any;
  navLinkGroupe?: INavLinkGroup[];
  onRenderLink?: IRenderFunction<INavLink>;
};

export const SideNavBar = ({
  toggleMenu,
  setToggleMenu,
  navLinkGroupe = [],
  onRenderLink,
}: Props) => {
  const navStyles: Partial<INavStyles> = {
    root: {
      width: toggleMenu ? 48 : 240,
      height: "100%",
      overflowY: "auto",
      boxSizing: "border-box",
      background: themePalette.white,
      zIndex: 2,
    },
    link: { fontSize: 14, padding: 10 },
    chevronButton: { left: "inherit", right: 0 },
  };
  return (
    <>
      <Stack
        style={{
          height: "100%",
          width: toggleMenu ? 48 : 240,
          borderRight: "1px solid rgb(237, 235, 233)",
          position: "fixed",
          top: 48,
          overflow: "auto",
        }}
      >
        <IconButton
          style={{
            width: 240,
            height: 48,
            borderBottom: "1px solid rgb(237, 235, 233)",
            padding: "16px 4px",
          }}
          styles={{
            root: { color: "inherit", backgroundColor: "inherit" },
            flexContainer: { justifyContent: "start", paddingLeft: 8 },
            rootHovered: { backgroundColor: themePalette.neutralLight },
          }}
          iconProps={{ iconName: "GlobalNavButton" }}
          onClick={() => setToggleMenu(!toggleMenu)}
        />
        <Nav
          onRenderLink={onRenderLink}
          styles={navStyles}
          groups={navLinkGroupe}
        />
      </Stack>
    </>
  );
};

export default SideNavBar;
