import { Icon, Text } from "@fluentui/react";

type LabelWithIconProps = {
  text: string;
  iconName?: string;
};

export const LabelWithIcon = ({ text, iconName }: LabelWithIconProps) => {
  return (
    <Text styles={{ root: { marginLeft: 10, paddingTop: 10 } }}>
      {iconName && <Icon iconName={iconName}></Icon>} {text}
    </Text>
  );
};
