import { DefaultButton, PrimaryButton, Stack, Text } from "@fluentui/react";
import { useFormState, ErrorMessageBar } from "@d4b/fluent-ui";
import React, { useEffect } from "react";
import { RSErrorType } from "@d4b/rs-core";
import { useCommand } from "@d4b/api";

type InlineFormProps<T, C> = {
  dataElement: T;
  showInvalidLink?: boolean;
  initFromState?: () => any;
  formState: useFormState<C>;
  children?: React.ReactNode;
  linkName: string;
  actionButtonText: string;
  onCollectErrors?: (errors: any, values?: C) => void;
  onTransformValues?: (current: C) => any;
  onServerReply?: (onServerReply: any) => void;
  onDismiss?: (val: any) => void;
  debug?: boolean;
};

export const InlineForm = <T, C>({
  dataElement,
  showInvalidLink = true,
  initFromState,
  formState,
  children,
  linkName,
  actionButtonText,
  onCollectErrors,
  onTransformValues,
  onServerReply,
  onDismiss,
  debug = false,
}: InlineFormProps<T, C>) => {
  const [rsError, setRSError] = React.useState<RSErrorType | false>();
  const {
    isSuccess,
    data: serverReply,
    isLoading,
    onSubmit,
    reset,
  } = useCommand(dataElement, linkName, (e: any) => {
    setRSError(e);
    reset();
  });
  const isValidLink = true;

  const { setValues, setErrors, cleanValues, values } = formState;
  useEffect(() => {
    if (!dataElement || !initFromState) return;
    const loadValues = initFromState();
    setValues(loadValues);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataElement]);
  useEffect(() => {
    if (!isSuccess || !serverReply) return;

    onServerReply && onServerReply(serverReply);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const onSubmitForm = (): void => {
    let errors: any = {};
    onCollectErrors && onCollectErrors(errors);
    setErrors && setErrors(errors);
    if (errors["rs:Form"]) {
      setRSError({
        errorMessage: "Form error",
        errorMessageDetails: errors["rs:Form"],
      });
      return;
    }
    if (JSON.stringify(errors) !== "{}") {
      console.log("onSubmitForm", errors);
      return;
    }

    const valuesTransformed = onTransformValues
      ? onTransformValues(cleanValues() as any)
      : cleanValues();

    onSubmit(valuesTransformed);
  };

  return (
    <>
      {dataElement && !isValidLink && showInvalidLink && (
        <span style={{ color: "red" }}>Invalid link '{linkName}'</span>
      )}

      {/* {JSON.stringify({commandError,isError,isLoading})} */}
      <ErrorMessageBar error={rsError} onDismiss={()=>setRSError(undefined)} />
      <form style={{ width: "100%" }} onSubmit={onSubmitForm}>
        {" "}
        {React.Children.toArray(children).map((child) => {
          if (!child) return null;
          const type = typeof (child as any).type;
          return type === "function"
            ? React.cloneElement(child as React.ReactElement, {
                formState,
              })
            : React.cloneElement(child as React.ReactElement);
        })}
        <PrimaryButton
          style={{ marginTop: 10 }}
          disabled={isLoading}
          text={actionButtonText}
          onClick={onSubmitForm}
        />
        <DefaultButton
          style={{ marginTop: 10, marginLeft: 10 }}
          disabled={isLoading}
          text="Cancel"
          onClick={onDismiss}
        />
      </form>

      {debug && (
        <Stack>
          <Text>Debug information</Text>
          <pre>{JSON.stringify({ values }, null, 2)}</pre>
        </Stack>
      )}
    </>
  );
};

export default InlineForm;
