import { Stack } from "@fluentui/react";
import {
  ErrorPage,
  PageHeader,
  useLocalStorage,
  UseUrlParams,
} from "@d4b/fluent-ui";
import React, { Children } from "react";
import { createContext, ReactNode, useContext, useState } from "react";
import { PageMetaData } from "lib";
import { SecondaryCommandeBarProps } from "../components";

type ListWithDetailsLayoutProps = {
  children?: ReactNode;
  metadata?: LayoutMetadata;
  extraButton?: ReactNode;
  filterParams?: UseUrlParams;
  error?: boolean;
};

type LayoutMetadata = {
  pageMetaData: PageMetaData;
};

const Context = createContext<any>({});

type LayoutState = {
  hidden?: boolean;
  setHidden: React.Dispatch<React.SetStateAction<false | undefined>>;
  modeView?: "grid" | "list";
  setModeView: (value: "grid" | "list") => void;
};

export const useLayout = (): LayoutState => useContext(Context);

const ListWithDetailsLayout = ({
  children,
  metadata,
  extraButton,
  filterParams,
  error = false,
}: ListWithDetailsLayoutProps) => {
  // TODO: Just an example .. maybe page data etc..
  const [hidden, setHidden] = useState<false>();
  const [modeView, setModeView] = useLocalStorage<"grid" | "list">(
    `Page:ViewMode:${metadata?.pageMetaData?.pageUid}`,
    "grid"
  );

  if (error)
    return (
      <ErrorPage
        title="Oups.."
        message="The resource you have requested is not found. If the problem doesn't go away, please contact support."
      />
    );

  const value: LayoutState = { hidden, setHidden, modeView, setModeView };
  const secondaryCommandeBarProps: SecondaryCommandeBarProps = {};
  if (metadata?.pageMetaData.hasModeView)
    secondaryCommandeBarProps.viewModeMenuProps = {
      defaultView: "grid",
      modeView: modeView,
      changeView: setModeView,
    };
  if (filterParams)
    secondaryCommandeBarProps.searcherProps = {
      filterParams: filterParams,
      options: filterParams.filterOptions,
      searcherOptions: [],
    };

  return (
    <Context.Provider value={value}>
      <Stack>
        {metadata && (
          <PageHeader
            pageMetaData={metadata?.pageMetaData}
            extraButton={extraButton}
            secondaryCommandeBarProps={secondaryCommandeBarProps}
          />
        )}
        {children}
      </Stack>
    </Context.Provider>
  );
};

type LayoutContentProps = {
  children?: ReactNode | ReactNode[];
};

export const LayoutContent = ({ children }: LayoutContentProps) => {
  const layout = useLayout();

  return (
    <>
      {!layout.hidden &&
        Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { layout });
          }
        })}
    </>
  );
};

export const LayoutGridView = ({ children }: LayoutContentProps) => {
  const layout = useLayout();

  return (
    <>
      {layout.modeView === "grid" &&
        Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { layout });
          }
        })}
    </>
  );
};

export const LayoutListView = ({ children }: LayoutContentProps) => {
  const layout = useLayout();

  return (
    <>
      {layout.modeView === "list" &&
        Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { layout });
          }
        })}
    </>
  );
};

ListWithDetailsLayout.Content = LayoutContent;
ListWithDetailsLayout.Grid = LayoutGridView;
ListWithDetailsLayout.List = LayoutListView;
export { ListWithDetailsLayout };

export default ListWithDetailsLayout;
